import React from "react";
import './Footer.css';
import droplove_black_logo from '../../assets/droplove_black_logo.png'
import discord from '../../assets/discord.png'
import twitter from '../../assets/twitter.png'

import { Container } from '@material-ui/core'

const Footer = (props) => {

  return (
    <div className="footer">
      <Container className="no-padding" maxWidth="xl">
        <div className="droplove-footer">
          <div className="row">
            <div className="col-md-3 footer-left">
              <a href="https://droplove.xyz/">
                <img src={droplove_black_logo} className="" alt="logo" />
              </a>
              <p>
                No-code tool to deploy your own NFT smart contract.
              </p>
              <div className="footer-menu">
                <ul>
                  <li>
                    <a href="https://discord.gg/juB69MkDF2"><img src={discord} className="" alt="logo" /></a>
                    <a href="https://twitter.com/droplovexyz"><img src={twitter} className="" alt="logo" /></a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7"></div> 
            <div className="col-md-2 footer-right">
              <ul>
                <li><a target="_blank" className="apply-btn-footer" href="https://github.com/Droplove">Github</a></li>
                <li><a className="apply-btn-footer" href="https://droplove.xyz/contact">Contact</a></li>
                <li><a className="apply-btn-footer" href="https://droplove.xyz/disclaimer">Disclaimer</a></li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
