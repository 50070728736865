import React, { useEffect, useState, useRef } from "react";
import './SilentMint.css';
import create_welcome_img from '../../assets/create_welcome_img.png'
import success_cat from '../../assets/cat.png'
import icon_button_back from '../../assets/Icon-button.png'
import metamask_anim from '../../assets/metamask_anim.gif'
import icon_metamask from '../../assets/icon-metamask.png'
import droplove_light_logo from '../../assets/droplove_light_logo.png'

import ERC721FullABI from '../../contract_abi/ERC721Full.json'
import ERC721Bytecode from '../../contract_abi/bytecode.json'
import DroplovePassNFT from '../../contracts/DroplovePassNFT.json'

import { useWeb3React } from "@web3-react/core"
import { injected } from "../../components/Wallet/connector"
import { Container, LinearProgress, TextField } from '@material-ui/core'
import axios from 'axios'
import { validate } from '../../utils/contractValidator'
import { api_uri } from "../../utils/networkSetup"
var PROD_OR_DEV_API = api_uri()

const SilentMint = (props) => {

    const [currentStep, setCurrentStep] = useState(1);
    const { active, account, library, connector, activate, deactivate } = useWeb3React()
    const [inputData, setInputData] = useState();
    const [inputMaxSupplyError, setInputMaxSupplyError] = useState(false);
    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(10);
    const [loading, setLoading] = useState(false);
    const [etherscanLink, setEtherscanLink] = useState();
    const [networkSelectorData, setNetworkSelectorData] = useState();
    const [networkName, setNetworkName] = useState();
    const [ntwrkId, setNtwrkId] = useState();
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const [backendDown, setBackendDown] = useState(false);
    const [balanceZero, setBalanceZero] = useState(false);
    const [mintPassBalance, setMintPassBalance] = useState(0);
    const [ugPassBalance, setUgPassBalance] = useState(0);
    const [userHasMintPass, setUserHasMintPass] = useState(false);
    const [userHasUGPass, setUserHasUGPass] = useState(false);
    const [authDone, setAuthDone] = useState(false);
    const progressRef = useRef(() => { });


    useEffect(() => {
        if (!active) {
            connectWallet()
        } else {
            verify()
            checkNetwork()
            isUserWhitelisted()
            checkAccountBalance()
        }
    }, [active]);

    useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });

    useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const verify = async () => {
        try {
            const resAccount = await axios.get(`${PROD_OR_DEV_API}/auth/verify/${account}`, {});
            if (resAccount.data.length === 0) {
                signup()
            } else {
                let userAccount = resAccount.data[0].publicAddress
                let nonce = resAccount.data[0].nonce
                signMessage(userAccount, nonce)
            }
        } catch (err) {
            if (err) {
                setBackendDown(true)
                if (JSON.parse(localStorage.getItem("user"))) {
                    localStorage.setItem("user", null);
                } 
                console.error('err ', err)
            }
        }
    }

    const signup = async () => {
        try {
            const resSignup = await axios.post(`${PROD_OR_DEV_API}/auth/signup`, {
                "publicAddress": account
            }, {});
        } catch (err) {
            console.error('err ', err)
        }
    }

    const signMessage = async (_userAccount, _nonce) => {
        try {
            await library.eth.personal.sign(
                library.utils.fromUtf8(`Welcome to Droplove! Your one-time nonce is: ${_nonce}`),
                _userAccount,
                (err, signature) => {
                    if (err) return err;
                    authenticate(_userAccount, _nonce, signature)
                }
            )
        } catch (err) {
            console.log(err)
        }
    }

    const authenticate = async (_userAccount, _nonce, _signature) => {
        try {
            const resLogin = await axios.post(`${PROD_OR_DEV_API}/auth/login`, {
                "userWallet": _userAccount,
                "userNonce": _nonce,
                "signature": _signature
            }, {});
            if (resLogin.statusText === 'OK') {
                localStorage.setItem("user", JSON.stringify(resLogin.data.accessToken));
                setAuthDone(true)
            }
        } catch (err) {
            console.error('err ', err)
        }
    }

    const isUserWhitelisted = async () => {
        // setLoading(true)
        try {
            const resUserData = await axios.get(`${PROD_OR_DEV_API}/whitelist/is-whitelisted/${account}`, {});
            // console.log('loadUserData ', resUserData.data[0])
            if (resUserData.data.length != 0) {
                setIsWhitelisted(resUserData.data[0])
            }
            // setLoading(false);
            hasMintPass()
            hasUGPass()
        } catch (err) {
            if (err) {
                setBackendDown(true)
                console.error('err ', err)
            }
        }
    }

    const hasMintPass = async () => {
        try {
            const droploveContract = new library.eth.Contract(
                DroplovePassNFT.abi,
                DroplovePassNFT.networks["1"].address
            );

            const balance = await droploveContract.methods
                .balanceOf(account)
                .call();

            // console.log('balance ', balance)

            setMintPassBalance(balance)

            if (balance > 0) {
                setUserHasMintPass(true)
            }

        } catch (error) {
            console.error("Error", error);
        }
    }

    const hasUGPass = async () => {
        // try {
        //     const droploveContract = new library.eth.Contract(
        //         DroplovePassNFT.abi,
        //         "0xAD9947CC6A21a50EB5Ccd767471Dea354Bcf9282"
        //     );

        //     const ugbalance = await droploveContract.methods
        //         .balanceOf(account)
        //         .call();

        //     console.log('ugbalance ', ugbalance)

        //     setUgPassBalance(ugbalance)

        //     if (ugbalance > 0) {
        //         setUserHasUGPass(true)
        //     }

        // } catch (error) {
        //     console.error("Error", error);
        // }
        // setUserHasUGPass(true)
        
    }

    const deployNewContract = async () => {

        try {
            const contract = new library.eth.Contract(ERC721FullABI.abi);

            const networkId = await library.eth.net.getId();

            const binaryContractTx = contract.deploy({
                data: ERC721Bytecode.object,
                arguments: [
                    inputData.inputName.data,
                    inputData.inputTicker.data,
                    "SETURI",
                    inputData.inputMaxSupply.data
                ],
            }).encodeABI();

            const receipt = await library.eth.sendTransaction({
                to: null,
                from: account,
                value: "0x00",
                data: binaryContractTx,
            })

            switch (networkId) {
                case 1:
                    setEtherscanLink(`https://etherscan.io/token/${receipt.contractAddress}`)
                    break;
                case 5:
                    setEtherscanLink(`https://goerli.etherscan.io/token/${receipt.contractAddress}`)
                    break;
            }

            const encodedContructorParams = await library.eth.abi.encodeParameters(
                ['string', 'string', 'string', 'uint256'],
                [
                    inputData.inputName.data,
                    inputData.inputTicker.data,
                    "SETURI",
                    inputData.inputMaxSupply.data
                ]
            )

            let encoded = encodedContructorParams.substring(2)

            saveNewContract(
                account,
                1,
                inputData.inputName.data,
                receipt.contractAddress,
                ERC721FullABI.abi,
                encoded,
                networkId,
                "",
                [],
                "",
                "",
                "",
                "",
                "",
                "",
            )

            validateNewContract(
                encoded,
                receipt.contractAddress,
                networkId
            )

            setLoading(false);

            next()

        } catch (error) {
            console.error("ERROR DEPLOYING NEW CONTRACT", error);
        }
    }

    const validateNewContract = async (_encodedParams, _contractAddress, _networkId) => {
        try {
            validate(_contractAddress, _encodedParams, _networkId)
        } catch (err) {
            console.error('err ', err)
        }
    }

    const saveNewContract = async (
        _userOwnerAddress,
        _productId,
        _contractName,
        _contractAddress,
        _contractAbi,
        _encodedArg,
        _networkId,
        _contractDescription,
        _mindedIds,
        _inputMaxSupply,
        _mintStartdate,
        _mintDeadline,
        _inputPrice,
        _initFeeCollector,
        _initFee
    ) => {
        try {
            const res = await axios.post(`${PROD_OR_DEV_API}/contracts/new`, {
                "userOwnerAddress": _userOwnerAddress,
                "productId": _productId,
                "contractName": _contractName,
                "contractAddress": _contractAddress,
                "contractAbi": _contractAbi,
                "constructorParams": _encodedArg,
                "networkId": _networkId,
                "contractDescription": _contractDescription,
                "mindedIds": _mindedIds,
                "maxSupply": _inputMaxSupply,
                "mintStartdate": _mintStartdate,
                "mintDeadline": _mintDeadline,
                "inputPrice": _inputPrice,
                "initFeeCollector": _initFeeCollector,
                "initFee": _initFee,
            }, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            // console.log('res ', res)
        } catch (err) {
            console.error('err ', err)
        }

        try {
            const resMet = await axios.post(`${PROD_OR_DEV_API}/metadata/create`, {
                "contractAddress": _contractAddress,
                "name": _contractName,
                "description": "My perfect NFT ...",
                "image": "imageurl",
                "website": "https://www.mywebsiteurl.nft",
                "attributes": []
            }, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            // console.log('res ', res)
        } catch (err) {
            console.error('err ', err)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        validateFieldsNameAndTickerAndAllowDeploy()
    };

    const checkNetwork = async () => {
        const networkId = await library.eth.net.getId();
        setNtwrkId(parseInt(networkId))
        setNetworkSelectorData(networkId)
        getNetworkName(networkId)
    }

    const connectWallet = async () => {
        try {
            await activate(injected)
        } catch (ex) {
            console.log(ex)
        }
    }

    const next = async () => {
        setCurrentStep(currentStep + 1);
    };

    const nextToMintPass = async () => {
        setCurrentStep(11);
    };

    const backFromMintPass = async () => {
        setCurrentStep(1);
    };

    const nextStepAndPreFill = async () => {
        setInputData({ ...inputData, ['inputMaxSupply']: { "data": 1, "errorText": "" } })
        setCurrentStep(currentStep + 2);
    };

    const back = async () => {
        setCurrentStep(currentStep - 1);
    };

    const validateFieldMaxSupplyAndNext = async () => {
        if (inputData === undefined || (inputData.inputMaxSupply && inputData.inputMaxSupply.data === "")) {
            setInputMaxSupplyError(true)
        } else {
            next()
        }
    }

    const validateFieldsNameAndTickerAndAllowDeploy = async () => {
        if (
            (inputData === undefined || (inputData.inputName && inputData.inputName.data === ""))
            &&
            inputData === undefined || (inputData.inputTicker && inputData.inputTicker.data === "")
        ) {
            setInputMaxSupplyError(true)
        } else {
            setLoading(true);
            deployNewContract();
        }
    }

    const handleChange = (e) => {
        const value = e.target.value;

        // console.log('handleChangehandleChange ', e.target.name, value)

        setInputData({ ...inputData, [e.target.name]: { "data": value, "errorText": "" } })
    }

    const handleNetworkChange = async (e) => {
        const value = e.target.value;

        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: library.utils.toHex(value) }]
            });
            window.location.reload();
        } catch (error) {
            console.error(error);
        }

    }

    const getNetworkName = (_networkId) => {
        switch (parseInt(_networkId)) {
            case 1:
                setNetworkName(`Ethereum Mainnet`)
                break;
            case 3:
                setNetworkName(`Ropsten Test Network (read only)`)
                break;
            case 42:
                setNetworkName(`Kovan Test Network (read only)`)
                break;
            case 4:
                setNetworkName(`Rinkeby Test Network (read only)`)
                break;
            case 5:
                setNetworkName(`Goerli Test Network`)
                break;
        }
    }

    const checkAccountBalance = async () => {
        let balance = await library.eth.getBalance(account)
        // console.log('balancebalancebalance ', balance)
        if (balance == 0) {
            setBalanceZero(true)
        }
    }

    switch (currentStep) {
        case 1:
            return (
                <div>
                    {!active ?
                        <Container className="no-padding" maxWidth="xl">
                            <div className="container droplove-page-create-body animate__animated animate__fadeIn">
                                <div className="create-step-one-silent-mint-section">
                                    <h1>Connect your wallet</h1>
                                    <p>Connecting your wallet is like “logging in” to Web3.<br />Currently we are supporting Metamask without mobile web UI, more wallets and mobile view UI will be available later.</p>
                                    <a target="_blank" href="https://metamask.io/"><button className="connect-your-wallet-step-metamask"><img src={icon_metamask} />Metamask</button></a>
                                    <br />
                                    <br />
                                    <small>If you need some help check out <a href="https://www.youtube.com/watch?v=Af_lQ1zUnoM"> tutorial here.</a></small>
                                </div>
                                {backendDown ? <div className="backenddown-alert"><p>{`Our servers are currently offline. We are upgrading the backend. Please try again later.`}</p></div> : null}
                            </div>
                        </Container>
                        :
                        <Container className="no-padding" maxWidth="xl">
                            <div className="container droplove-page-create-body animate__animated animate__fadeIn">
                                {/* <div className="wizard-back-button">
                                    <img onClick={back} src={icon_button_back} />
                                </div> */}
                                <div className="create-step-two-silent-mint-section">
                                    <h1>1/3<br />Make sure you are on the right network</h1>
                                    <p> If you would like to test, please choose one of the test networks.<br />If you are ready to go Mainnet, feel free.</p>
                                    {/* <p>Your current network is - <strong>{networkName}</strong></p> */}
                                    <form>
                                        <div className='step-network-selector'>
                                            <div className="step-network-selector-input form-group">
                                                <select
                                                    value={networkSelectorData}
                                                    onChange={handleNetworkChange}
                                                    className="form-control"
                                                    id="stepNetworkFormControl"
                                                >
                                                    <option value={1}>Ethereum Mainnet</option>
                                                    <option value={5}>Goerli Test Network</option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                    {(ntwrkId === 42 || ntwrkId === 3 || ntwrkId === 4) &&
                                        <small>Your selected test network is deprecated and read-only. Please select from the list of supported networks.</small>
                                    }
                                    {ntwrkId === 1 ? (
                                        !backendDown ?
                                            <div className="wizard-btn-steps">
                                                <button onClick={next} className="btn-white-wizard">{`Next step`}</button>
                                            </div>
                                            :
                                            <div className="wizard-btn-steps">
                                                <button onClick={nextToMintPass} className="btn-white-wizard">{`Next step`}</button>
                                            </div>
                                    )
                                        :
                                        (!backendDown ?
                                            <div className="wizard-btn-steps">
                                                <button onClick={next} className="btn-white-wizard">{`Next step`}</button>
                                            </div>
                                            :
                                            <div className="backenddown-alert"><p>{`---> Our servers are currently offline. We are upgrading the backend. Please try again later. <---`}</p></div>
                                        )
                                    }
                                </div>
                                <br />

                            </div>
                        </Container>
                    }
                </div>
            );
        case 11:
            return (
                <div>
                    <Container className="no-padding" maxWidth="xl">
                        <div className="container droplove-page-create-body">
                            <div className="create-step-one-silent-mint-section">
                                <h1>Droplove is currently open and free to<br />use for holders of DRPLV Mint Pass</h1>
                                <p>Without Pass, you are still able to deploy on test networks.</p>
                                <div className="wizard-btn-steps">
                                    <a href="/#mintpass"><button className="full-btn-big">{`Buy Mint Pass`}</button></a>
                                    <br />
                                    <br />
                                    <a className="backBtnMnt" onClick={backFromMintPass}>{`or let’s try on test networks`}</a>
                                </div>
                                <div className="mintpass-section animate__animated animate__fadeIn">
                                    <div className="mintpass-section-top">
                                        <img src={droplove_light_logo} className="" alt="Droplove logo" />
                                        <h2>Get DRPLV Mint Pass</h2>
                                        <p>We have built Droplove to democratize the world of NFT drops. Get Mint Pass and join our community of early adopters, who will be able to use our no-code NFT contract creator first. </p>
                                        <p>Each of 990 Droplove Mint Passes has three utilities:</p>
                                        <ul>
                                            <li>Access to no-code contract creator, enabling you to drop your own NFTs.</li>
                                            <li>Presale access with discount to all upcoming drops, with each pass guaranteeing 1 discounted mint from whitelist.</li>
                                            <li>Forever presale access to all future drops minted with no-code contract deployer.</li>
                                        </ul>
                                        <p>At Droplove, we are creating tools for people following their hearts. Join us in changing web3 to a more fun and equal place, open for everyone.</p>
                                    </div>
                                    <div className="mintpass-section-bottom">
                                        {/* <div className="mint-counter">
                <img onClick={() => counterMinus()} src={iconMinus} className="" alt="logo" />
                <span className="mintpass-counter">{mintAmount}</span>
                <img onClick={() => counterPlus()} src={iconPlus} className="" alt="logo" />
              </div> */}
                                        <div className="pass-minter">
                                            <a href="/#mintpass"><button className='full-btn-big'>Buy Mint Pass</button></a>
                                            {/* <button className="full-btn-big" onClick={() => mintPass(mintAmount)}>{active ? "Mint your tickets" : "Connect your wallet"}</button> */}
                                            {/* <div className="supply-counter">{tokenTotalSupply}/990 minted</div> */}
                                            <p className="price-info">0.035 Eth</p>
                                            <a className="apply-btn apply-btn-mintpass" href="https://discord.gg/juB69MkDF2">Join our Discord</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            );
        case 2:
            return (
                <div>

                    <Container className="no-padding" maxWidth="xl">
                        <div className="container droplove-page-create-body">
                            <div className="create-step-one-silent-mint-section">
                                <h1>2/3<br />Are you looking to create single edition<br />NFT, or a collection?</h1>
                                <p>Single-edition means, that only one NFT will be possible to mint.<br />Collection means, there will be possible to mint any number of NFTs.</p>
                                <div>
                                    <button onClick={nextStepAndPreFill} className="btn-white-wizard">{`Single-edition`}</button>
                                    <button onClick={next} className="btn-white-wizard">{`Collection of NFT's`}</button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            );
        case 3:
            return (
                <div>
                    <Container className="no-padding" maxWidth="xl">
                        <div className="container droplove-page-create-body animate__animated animate__fadeIn">
                            <div className="wizard-back-button">
                                <img onClick={back} src={icon_button_back} />
                            </div>
                            <div className="create-step-two-silent-mint-section">
                                <h1>How big is your collection?</h1>
                                <p>How many NFTs are you looking to creeate? Field is mandatory and<br />there is no option to change it later.</p>
                                <form>
                                    <TextField
                                        id="maxSupply-input"
                                        className={`create-wizard-input ${inputMaxSupplyError ? "input-err" : null}`}
                                        value={inputData && inputData.inputMaxSupply && inputData.inputMaxSupply.data ? inputData.inputMaxSupply.data : ''}
                                        variant="outlined"
                                        label="Max supply"
                                        name="inputMaxSupply"
                                        onChange={handleChange}
                                        required
                                    />
                                </form>
                                <div className="wizard-btn-steps">
                                    <button onClick={validateFieldMaxSupplyAndNext} className="btn-white-wizard">{`Next step`}</button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            );
        case 4:
            return (
                <div>
                    <Container className="no-padding" maxWidth="xl">
                        <div className="container droplove-page-create-body animate__animated animate__fadeIn">
                            <div className="wizard-back-button">
                                <img onClick={back} src={icon_button_back} />
                            </div>
                            <div className="create-step-two-silent-mint-section">
                                <h1>3/3<br />What’s the name of your NFT?</h1>
                                <p>Name of your NFT will appear publicly on your collection.<br />Field is mandatory.</p>
                                <form>
                                    <TextField
                                        id="name-input"
                                        className="create-wizard-input"
                                        value={inputData && inputData.inputName && inputData.inputName.data ? inputData.inputName.data : ''}
                                        variant="outlined"
                                        label="Name of your NFT"
                                        name="inputName"
                                        onChange={handleChange}
                                        required
                                    />
                                    <div className="airdrop-mint-between-place"></div>
                                    <h1>Name your ticker</h1>
                                    <p>Ticker, or a stock symbol, is an abbreviation used to uniquely identify publicly traded shares. A stock<br />symbol may consist of letters, numbers or a combination of both. Here are some examples: BAYC<br />(standing for Bored Ape Yacht Club) or DRPLV ( Standing for Droplove). This field is mandatory.</p>
                                    <TextField
                                        id="ticker-input"
                                        className="create-wizard-input"
                                        value={inputData && inputData.inputTicker && inputData.inputTicker.data ? inputData.inputTicker.data : ''}
                                        variant="outlined"
                                        label="Ticker symbol"
                                        name="inputTicker"
                                        onChange={handleChange}
                                        required
                                    />
                                </form>


                                {loading ? <div className="loading_progress_bar">
                                    <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                                    <small>Waiting for the miners...</small>
                                    <br />
                                    <img src={metamask_anim} />
                                </div>
                                    :
                                    <div className="wizard-btn-steps">
                                        <div>
                                            {ntwrkId === 1 ? (
                                                isWhitelisted || userHasMintPass ?
                                                    <button onClick={handleSubmit} className="full-btn-big">{`Deploy`}</button>
                                                    :
                                                    <div className="mintpass-sorry-msg">
                                                        <p>We are sorry, it seems like you don't own a Mintpass on selected wallet or you are not whitelisted.</p>
                                                        <p>Without Pass, you are still able to deploy on test networks.</p>
                                                        <div className="wizard-btn-steps">
                                                            <a href="/#mint-pass"><button className="btn-white-wizard">{`Buy mintpass`}</button></a>
                                                        </div>
                                                    </div>
                                            )
                                                :
                                                <button onClick={handleSubmit} className="full-btn-big">{`Deploy`}</button>
                                            }

                                        </div>
                                        <p>*I read and agree with <a target="_blank" href="/disclaimer">{`the disclaimer`}</a></p>
                                        {balanceZero && ntwrkId !== 1 ?
                                            <div>
                                                <small>It looks like you don't have any test ETH. <a target="_blank" href="https://faucet.rinkeby.io/">Get one here</a> or read our <a target="_blank" href="https://medium.com/droplove/how-to-get-free-eth-test-coins-5527aea6b7e0">tutorial on how to.</a></small>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </Container >
                </div >
            );
        case 5:
            return (
                <div>
                    <Container className="no-padding" maxWidth="xl">
                        <div className="container droplove-page-create-body animate__animated animate__fadeIn">
                            <div className="create-step-two-silent-mint-section">
                                {/* <img src={success_cat} /> */}
                                {/* <div style="width:100%;height:0;padding-bottom:100%;position:relative;"><iframe src="https://giphy.com/embed/TgKEjjz1lzjmEsuz80" width="100%" height="100%" style="position:absolute" frameBorder="0" class="giphy-embed" allowFullScreen></iframe></div><p><a href="https://giphy.com/gifs/dance-cat-dancing-TgKEjjz1lzjmEsuz80">via GIPHY</a></p> */}
                                <div className='intro-gif-success' styles={{ "width": "100%", "height": 0, "padding-bottom": "75%", "position": "relative" }}><iframe src="https://giphy.com/embed/TgKEjjz1lzjmEsuz80" width="100%" height="100%" styles="position:absolute" frameBorder="0" className="giphy-embed"></iframe></div>
                                <h1>High five!</h1>
                                <p>You just created a smart contract!</p>
                                <div>
                                    <a href="/"><button className="full-btn-big">{`Manage contracts`}</button></a>
                                </div>
                                <br />
                                <div>
                                    <a target="_blank" href={etherscanLink}><button className="full-btn-small">{`See it on Etherscan`}</button></a>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            );
        default:
            return (
                <div>
                    <Container className="no-padding" maxWidth="xl">
                        <div className="container droplove-page-create-body">
                            <div className="create-welcome-section">
                                <h1>Oh... something went wrong... please, refresh page and connect to Metamask again.</h1>
                            </div>
                        </div>
                    </Container>
                </div>
            )
    }
}

export default SilentMint;
