import './MintPage.css';
//assests import
import ERC721FullABI from '../../contract_abi/ERC721PublicMint.json'
import publicmintbg from '../../assets/public_mint_bg.png'
import iconMinus from '../../assets/pm-icon-minus.png'
import iconPlus from '../../assets/pm-icon-plus.png'
import extlinkicon from '../../assets/ext-link-icon.png'
import metamask_anim from '../../assets/metamask_anim.gif'
import icon_metamask from '../../assets/icon-metamask.png'



//libs imports
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { Container, CircularProgress, LinearProgress, TextField, Modal, Dialog } from '@material-ui/core'
// import { Alert } from '@material-ui';
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../components/Wallet/connector"
import axios from 'axios'
import Datetime from "react-datetime";
import ImageUploading from "react-images-uploading";
import { api_uri } from "../../utils/networkSetup";
import web3Utils from 'web3-utils';
var PROD_OR_DEV_API = api_uri();

const MintPage = () => {
  const params = useParams()
  let history = useHistory()
  const { active, account, library, connector, activate, deactivate } = useWeb3React()
  const [inputData, setInputData] = useState()
  const [wrongNetwork, setWrongNetwork] = useState(false)
  const [ntwId, setNtwId] = useState()
  const [etherscanLink, setEtherscanLink] = useState()
  const [networkName, setNetworkName] = useState()
  // const [loadedContractData, setLoadedContractData] = useState()
  const [loading, setLoading] = useState(false)
  const [loadedMetadata, setLoadedMetadata] = useState()
  const [name, setName] = useState("Droplove | Create, Sell & Manage")
  const [symbol, setSymbol] = useState()
  const [ipfsLink, setIpfsLink] = useState()
  const [contractOwner, setContractOwner] = useState()
  const [maxSupply, setMaxSupply] = useState(0)
  const [totalSupply, setTotalSupply] = useState(0)
  const [mintStartdate, setMintStartdate] = useState(0)
  const [mintDeadline, setMintDeadline] = useState(0)
  const [parsedMintDeadline, setParsedMintDeadline] = useState(0)
  const [currentPrice, setCurrentPrice] = useState(0)
  const [isPaused, setIsPaused] = useState(0)
  const [mintAmount, setMintAmount] = useState(1);
  const [mintLoading, setMintLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);
  const [mintSuccess, setMintSuccess] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [chaindataLoading, setChaindataLoading] = useState(false)
  const [backendDown, setBackendDown] = useState(false);


  useEffect(() => {
    document.body.style.backgroundImage = `none`
    document.getElementsByClassName("fixed-header")[0].style.backgroundColor = `#fff`
    if (!active) {
      connectWallet()
    } else {
      loadContractProps(params.contractAddress)
      checkNetwork()
    }
  }, [active]);

  const connectWallet = async () => {
    try {
      await activate(injected)
    } catch (ex) {
      console.log(ex)
    }
  }

  // const getContractData = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await axios.get(`${PROD_OR_DEV_API}/contracts/detail/${params.contractAddress}`, {
  //       headers: {
  //         token: "Bearer " + JSON.parse(localStorage.getItem("user"))
  //       }
  //     });
  //     setLoadedContractData(res.data[0])
  //     setLoading(false);
  //   } catch (err) {
  //     setBackendDown(true)
  //     console.error('err ', err)
  //   }
  // }

  const getContractMetadata = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${PROD_OR_DEV_API}/metadata/${params.contractAddress}/0`, {
        headers: {
          // token: "Bearer " + JSON.parse(localStorage.getItem("user"))
        }
      });
      setLoadedMetadata(res.data)
      setLoading(false);
    } catch (err) {
      console.error('err ', err)
    }
  }

  const loadContractProps = async (_contractAddress) => {
    // getContractData()
    getContractMetadata()
    setChaindataLoading(true)
    try {
      const networkId = await library.eth.net.getId();
      const contract = new library.eth.Contract(
        ERC721FullABI.abi,
        _contractAddress
      );

      setName(await contract.methods
        .name()
        .call())

      setSymbol(await contract.methods
        .symbol()
        .call())

      setIpfsLink(await contract.methods
        ._baseTokenURI()
        .call())

      setContractOwner(await contract.methods
        .MINTER_ROLE()
        .call())

      setTotalSupply(await contract.methods
        .totalSupply()
        .call())

      setMaxSupply(await contract.methods
        ._maxSupply()
        .call())

      setCurrentPrice(await contract.methods
        .mintPrice()
        .call())

      setMintStartdate(await contract.methods
        ._startDate()
        .call())

      let chainTimestamp = await contract.methods
        ._endDate()
        .call()

      let parsed = new Date(parseInt(chainTimestamp) * 1000)
      var month = parsed.getUTCMonth(); //months from 1-12
      var day = parsed.getUTCDate();
      var year = parsed.getUTCFullYear();
      let hours = parsed.getHours();
      let minutes = parsed.getMinutes();

      let parsedDateTime = 'Mint open until ' + day + '.' + month + '.' + year + ' / ' + hours + ':' + (minutes < 9 ? '0' + minutes : minutes)

      setMintDeadline(parseInt(chainTimestamp))
      setParsedMintDeadline(parsedDateTime)

      setIsPaused(await contract.methods
        .paused()
        .call())

      setChaindataLoading(false)

    } catch (error) {
      console.error('ERROR LOADING CONTRACT PROPS: ', error)
    }
  }


  const checkNetworkIdForEthscan = (_networkId) => {
    switch (parseInt(_networkId)) {
      case 1:
        setEtherscanLink(`https://etherscan.io/token/${params.contractAddress}`)
        break;
      case 3:
        setEtherscanLink(`https://ropsten.etherscan.io/token/${params.contractAddress}`)
        break;
      case 4:
        setEtherscanLink(`https://rinkeby.etherscan.io/token/${params.contractAddress}`)
        break;
      case 42:
        setEtherscanLink(`https://kovan.etherscan.io/token/${params.contractAddress}`)
        break;
      case 5:
        setEtherscanLink(`https://goerli.etherscan.io/token/${params.contractAddress}`)
        break;
      default:
        setEtherscanLink(false);
    }
  }

  const getNetworkName = (_networkId) => {
    switch (parseInt(_networkId)) {
      case 1:
        setNetworkName(`Ethereum Mainnet`)
        break;
      case 3:
        setNetworkName(`Ropsten Test Network (read only)`)
        break;
      case 42:
        setNetworkName(`Kovan Test Network (read only)`)
        break;
      case 4:
        setNetworkName(`Rinkeby Test Network (read only)`)
        break;
      case 5:
        setNetworkName(`Goerli Test Network`)
        break;
      default:
        setEtherscanLink(false);
    }
  }

  const checkNetwork = async () => {
    const networkId = await library.eth.net.getId();
    setNtwId(networkId)
    if (params.networkId != networkId) {
      setWrongNetwork(true)
    } else {
      checkNetworkIdForEthscan(params.networkId)
      getNetworkName(params.networkId)
    }
  }

  const mint = async (_mintAmount) => {

    const networkId = await library.eth.net.getId();

    let calcMintPrice = await calculatePrice()

    // console.log('currentPrice: ', currentPrice)
    // console.log('mint ', _mintAmount, calcMintPrice)

    // return false

    setMintLoading(true);

    const contract = new library.eth.Contract(
      ERC721FullABI.abi,
      params.contractAddress
    );

    try {
      const receipt = await contract.methods
        .mint(account, _mintAmount)
        .send({
          from: account,
          value: calcMintPrice
        });

      // console.log('receipt ', receipt)

      switch (networkId) {
        case 1:
          setEtherscanLink(`https://etherscan.io/tx/${receipt.transactionHash}`)
          break;
        case 5:
          setEtherscanLink(`https://goerli.etherscan.io/tx/${receipt.transactionHash}`)
          break;
      }

      setMintSuccess(receipt.status)

      setMintLoading(false);
    } catch (error) {
      alert(error.message);
      console.error(error);
    }
  }

  const calculatePrice = async () => {
    return (mintAmount * currentPrice) + (mintAmount * 600000000000000);
  }

  const counterPlus = async () => {
    if (mintAmount >= 1 && mintAmount <= 5 && mintAmount != 5)
      setMintAmount(mintAmount + 1)
  }

  const counterMinus = async () => {
    if (mintAmount != 1 && mintAmount <= 5)
      setMintAmount(mintAmount - 1)
  }

  const handleDialogClose = () => {
    setMintSuccess(false);
  };

  return (
    <div>
      {wrongNetwork ?
        <div className='detail-top-header'>
          <br />
          <br />
          <br />
          <h2>You are trying to load data from incorrect network.</h2>
          <br />
          {params.networkId == 1 ? "Please change your metamask network to 'Mainnet' and refresh page." : null}
          {params.networkId == 3 ? "Please change your metamask network to 'Ropsten' and refresh page." : null}
          {params.networkId == 4 ? "Please change your metamask network to 'Rinkeby' and refresh page." : null}
          {params.networkId == 42 ? "Please change your metamask network to 'Kovan' and refresh page." : null}
          {params.networkId == 5 ? "Please change your metamask network to 'Goerli' and refresh page." : null}
        </div>
        :
        <div>
          <Helmet>
            <title>{name} | Powered by Droplove</title>
            <meta name="description" content={loadedMetadata && loadedMetadata.description} />
            <meta property="og:image" content={loadedMetadata && loadedMetadata.image}></meta>
          </Helmet>
          <div className='mint-page-body'>
            <div className='row'>
              <div className='mint-page-collection-header'>
                <h1>{name}</h1>
                <p><div className={`connector-indicator-${active ? 'green' : 'red'}`}></div>{params.contractAddress}</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className='mint-page-left'>
                  <div className='mint-page-left-image'>
                    {loadedMetadata && loadedMetadata.image !== 'imageurl' ?
                      <img src={loadedMetadata && loadedMetadata.image} alt={name} />
                      :
                      <img src={publicmintbg} alt={name} />
                    }
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                {active ?
                  <div className='mint-page-right-info'>
                    {/* {isPaused ?
                    <h1>Coming soon</h1>
                    :
                    <h1>Mint is open</h1>
                  } */}

                    {!chaindataLoading ?

                      (
                        (parseInt((new Date().getTime() / 1000).toFixed(0)) < parseInt(mintStartdate) ? <h1>Get ready</h1> : null)
                      )
                      :
                      <div className="update-owner-button-loading"><CircularProgress /></div>
                    }

                    {!chaindataLoading && active ?

                      (
                        (parseInt((new Date().getTime() / 1000).toFixed(0)) > parseInt(mintDeadline) && parseInt(maxSupply) === 0 ? <h1>Sale closed</h1> : null)
                      )
                      :
                      <h1>Connect wallet</h1>
                    }

                    {!chaindataLoading ?

                      (
                        (parseInt((new Date().getTime() / 1000).toFixed(0)) > parseInt(mintStartdate) && parseInt((new Date().getTime() / 1000).toFixed(0)) < parseInt(mintDeadline) ? <h1>Sale open!</h1> : null)
                      )
                      :
                      null
                    }

                    {!chaindataLoading && parseInt(maxSupply) > 0 ?
                      <h1>Mint is open</h1>
                      :
                      null
                    }

                    {!chaindataLoading ?
                      <>
                        <div className='mint-page-right-token-info'>
                          <p>Token standard: <strong>{'ERC721'}</strong></p>
                          <p>Network: <strong>{'Ethereum'}</strong></p>
                        </div>

                        <div className='mint-page-right-website'>
                          <a target="_blank" href={loadedMetadata && loadedMetadata.website}><img src={extlinkicon} alt={name} /></a>
                        </div>

                        <div className='mint-page-right-description'>
                          <p>{loadedMetadata && loadedMetadata.description}</p>
                        </div>
                      </>
                      :
                      null
                    }

                    <div className="mintpass-section-bottom">
                      {
                        parseInt((new Date().getTime() / 1000).toFixed(0)) > parseInt(mintDeadline) ?
                          (
                            parseInt(maxSupply) !== 0 ?
                              <div className="pm-mint-counter">
                                <img onClick={() => counterMinus()} src={iconMinus} className="" alt="logo" />
                                <span className="pm-mint-counter-info">{mintAmount}</span>
                                <img onClick={() => counterPlus()} src={iconPlus} className="" alt="logo" />
                              </div>
                              :
                              null
                          )
                          :
                          <div className="pm-mint-counter">
                            <img onClick={() => counterMinus()} src={iconMinus} className="" alt="logo" />
                            <span className="pm-mint-counter-info">{mintAmount}</span>
                            <img onClick={() => counterPlus()} src={iconPlus} className="" alt="logo" />
                          </div>
                      }

                      {mintLoading ?
                        <div className="loading_progress_bar">
                          <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                          <small>Waiting for the miners...</small>
                          <br />
                          <small><strong>DON'T CLOSE THIS SCREEN</strong></small>
                          <br />
                          <img src={metamask_anim} />
                        </div>
                        :
                        <div className="pass-minter">
                          {/* {console.log('maxSupply ', maxSupply)} */}

                          {
                            parseInt((new Date().getTime() / 1000).toFixed(0)) > parseInt(mintDeadline) ?
                              (
                                parseInt(maxSupply) !== 0 ?
                                  <button className="pm-full-btn" onClick={() => mint(mintAmount)}>{active ? "Mint" : "Connect wallet"}</button>
                                  :
                                  null
                              )
                              :
                              <button className="pm-full-btn" onClick={() => mint(mintAmount)}>{active ? "Mint" : "Connect wallet"}</button>
                          }
                          {!chaindataLoading ?
                            <>
                              <p className="price-info"><strong>Price:</strong> {currentPrice ? web3Utils.fromWei(currentPrice) : currentPrice} Eth / 1 {symbol}</p>
                              {parseInt(mintStartdate) !== 0 ?
                                <p>{parsedMintDeadline}</p>
                                :
                                <p>{`Minted: ${totalSupply} / ${maxSupply}`}</p>
                              }
                            </>
                            :
                            <div className="update-owner-button-loading"><CircularProgress /></div>
                          }

                        </div>
                      }

                      <Dialog onClose={handleDialogClose} open={mintSuccess}>
                        <div className='mint-success-dialog'>
                          <div className='mint-success-dialog-body'>
                            <h1>Successfully minted!</h1>
                            <p>Please check your wallet</p>
                            <br />
                            <a target="_blank" href={etherscanLink}>
                              <button className='pm-small-btn'>See on Etherscan</button>
                            </a>
                          </div>
                        </div>
                      </Dialog>

                    </div>
                  </div>
                  :
                  <div className='mint-page-right-info-disconneted'>
                    <div className="animate__animated animate__fadeIn">
                      <div className="">
                        <h1>Connect your wallet</h1>
                        <p>Connecting your wallet is like “logging in” to Web3.<br />Currently we are supporting Metamask without mobile web UI, more wallets and mobile view UI will be available later.</p>
                        <a target="_blank" href="https://metamask.io/"><button className="connect-your-wallet-step-metamask"><img src={icon_metamask} />Metamask</button></a>
                        <br />
                        <br />
                        <small>If you need some help check out <a href="https://www.youtube.com/watch?v=Af_lQ1zUnoM"> tutorial here.</a></small>
                      </div>
                      {backendDown ? <div className="backenddown-alert"><p>{`Our servers are currently offline. We are upgrading the backend. Please try again later.`}</p></div> : null}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default MintPage;
