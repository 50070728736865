import './Dashboard.css';
//assests import
import profile_pic_placeholder from '../../assets/profile-pic.png'
import plus_icon_btn from '../../assets/dashboard-Icon-button.png'
import globe_icon from '../../assets/globe-icon.png'
import twitter_icon from '../../assets/twitter.png'
import discord_icon from '../../assets/edit-profile-discord.png'
import dashboard_background from '../../assets/dashboard-background.png'
import add_profile_pic from '../../assets/profile_placeholder.png'

//libs imports
import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../components/Wallet/connector"
import { Container, CircularProgress } from '@material-ui/core'
import axios from 'axios'

// component import
import DashboardCard from '../../components/DashboardCard/DashboardCard';
import { api_uri } from "../../utils/networkSetup"
var PROD_OR_DEV_API = api_uri()

const Dashboard = () => {

    const { active, account, library, connector, activate, deactivate } = useWeb3React()
    const [allContracts, setAllContracts] = useState([]);
    const [networkSelectorData, setNetworkSelectorData] = useState();
    const [authDone, setAuthDone] = useState(false);
    const [loadedUserData, setLoadedUserData] = useState();
    const [profileLoading, setProfileLoading] = useState();
    const [contractsLoading, setContractsLoading] = useState(false);
    const [backendDown, setBackendDown] = useState(false);

    useEffect(() => {
        document.body.style.backgroundImage = `url(${dashboard_background})`
        if (!active) {
            connectWallet()

        } else {
            // console.log('exists? ', JSON.parse(localStorage.getItem("user")))
            // if (JSON.parse(localStorage.getItem("user"))) {
            //     setAuthDone(true)
            //     checkNetworkAndLoadContracts()
            // } else {
            //     verify()
            // }
            verify()
        }
    }, [active]);

    const verify = async () => {
        try {
            const resAccount = await axios.get(`${PROD_OR_DEV_API}/auth/verify/${account}`, {});
            if (resAccount.data.length === 0) {
                signup()
            } else {
                let userAccount = resAccount.data[0].publicAddress
                let nonce = resAccount.data[0].nonce
                signMessage(userAccount, nonce)
            }
        } catch (err) {
            if (err) {
                setBackendDown(true)
                if (JSON.parse(localStorage.getItem("user"))) {
                    localStorage.setItem("user", null);
                } 
                console.error('err ', err)
            }
        }
    }

    const signup = async () => {
        try {
            const resSignup = await axios.post(`${PROD_OR_DEV_API}/auth/signup`, {
                "publicAddress": account
            }, {});
        } catch (err) {
            console.error('err ', err)
        }
    }

    const signMessage = async (_userAccount, _nonce) => {
        try {
            await library.eth.personal.sign(
                library.utils.fromUtf8(`Welcome to Droplove! Your one-time nonce is: ${_nonce}`),
                _userAccount,
                (err, signature) => {
                    if (err) return err;
                    authenticate(_userAccount, _nonce, signature)
                }
            )
        } catch (err) {
            console.log(err)
        }
    }

    const authenticate = async (_userAccount, _nonce, _signature) => {
        try {
            const resLogin = await axios.post(`${PROD_OR_DEV_API}/auth/login`, {
                "userWallet": _userAccount,
                "userNonce": _nonce,
                "signature": _signature
            }, {});
            if (resLogin.statusText === 'OK') {
                localStorage.setItem("user", JSON.stringify(resLogin.data.accessToken));
                setAuthDone(true)
                checkNetworkAndLoadContracts()
            }
        } catch (err) {
            console.error('err ', err)
        }
    }

    const checkNetworkAndLoadContracts = async () => {
        const networkId = await library.eth.net.getId();
        setNetworkSelectorData(networkId)
        // setInputData({ ...inputData, ['dashboardNetworkSelect']: { "data": networkId, "errorText": "" } })
        getAllContracts(networkId)
        loadUserData()
    }

    const connectWallet = async () => {
        try {
            await activate(injected)
        } catch (ex) {
            console.log(ex)
        }
    }

    const getAllContracts = async (_networkId) => {
        try {
            setContractsLoading(true)
            const res = await axios.get(`${PROD_OR_DEV_API}/contracts/all/${_networkId}/${account}`, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            setAllContracts(res.data)
            setContractsLoading(false)
        } catch (err) {
            if (err) {
                // setBackendDown(true)
                if (JSON.parse(localStorage.getItem("user"))) {
                    localStorage.setItem("user", null);
                    verify()
                } 
                console.error('err ', err)
            }
        }
    }

    const loadUserData = async () => {
        setProfileLoading(true)
        try {
            const resUserData = await axios.get(`${PROD_OR_DEV_API}/user-data/${account}`, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            // console.log('loadUserData ', resUserData.data[0])
            if (resUserData.data.length != 0) {
                setLoadedUserData(resUserData.data[0])
            }
            setProfileLoading(false);
        } catch (err) {
            console.error('err ', err)
        }
    }

    const handleNetworkChange = async (e) => {
        const value = e.target.value;
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: library.utils.toHex(value) }]
            });
            window.location.reload();
        } catch (error) {
            console.error(error);
        }
    }

    const parseDate = (_dateTime) => {
        var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        var parsedDate = new Date(_dateTime);
        return `${months[parsedDate.getMonth()]} ${parsedDate.getFullYear()}`
    }

    return (
        <div>
            <div className="container-fluid">
                <div className='droplove-page-dashboard-body'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='dashboard-profile-pic'>
                                <img src={add_profile_pic} />
                            </div>
                            <div className='dashboard-profile-name'>
                                <h3>
                                    {loadedUserData ? `@` + loadedUserData.name : '@Unnamed'}
                                </h3>
                            </div>
                            <div className='dashboard-profile-wallet'>
                                {active ? `${account.slice(0, 8)}...` : '...'}
                            </div>
                            <div className='dashboard-btn-edit-div'>
                                <a href='/edit-profile'><button className='dashboard-btn-edit-profile'>Edit Profile</button></a>
                            </div>
                            <div className='dashboard-profile-info'>
                                <h3>Links</h3>
                                <ul>
                                    <li><a href={loadedUserData ? `https://${loadedUserData.website}` : 'http://...'}><img src={globe_icon} /> {loadedUserData ? loadedUserData.website : 'http://...'}</a></li>
                                    <li><a href={loadedUserData ? `https://twitter.com/${loadedUserData.twitter}` : '@unnamed'}><img src={twitter_icon} /> {loadedUserData ? loadedUserData.twitter : '@unnamed'}</a></li>
                                    <li><a href={loadedUserData ? `https://discordapp.com/users/${loadedUserData.discord}` : 'Discord ID'}><img src={discord_icon} /> {loadedUserData ? loadedUserData.discord : 'Discord ID'}</a></li>
                                </ul>
                            </div>
                            <div className='dashboard-profile-info-joined'>
                                {loadedUserData ?
                                    <>
                                        <h4>Joined</h4>
                                        <p>{parseDate(loadedUserData.createdAt)}</p>
                                    </>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <div className='dashboard-top-header'>
                                <h1 className='animate__animated animate__bounce'>Deployed Smart Contracts</h1>
                            </div>
                            <div className='network-selector'>
                                <div className="netowrk-selector-input form-group">
                                    <select
                                        className="form-control"
                                        id="networkFormControl"
                                        name='dashboardNetworkSelect'
                                        value={networkSelectorData}
                                        onChange={handleNetworkChange}
                                    >
                                        <option>-- Select network --</option>
                                        <option value={1}>Ethereum Mainnet</option>
                                        <option value={5}>Goerli Test Network</option>
                                        <option value={3}>Ropsten Test Network (read only)</option>
                                        <option value={42}>Kovan Test Network (read only)</option>
                                        <option value={4}>Rinkeby Test Network (read only)</option>
                                    </select>
                                </div>
                                <label className='network-selector-label'>Select network</label>
                            </div>
                            <div className='contract-list'>
                                <div className='col-md-4'>
                                    <a href="/create">
                                        <div className='contract-list-add-new animate__animated animate__fadeIn'>
                                            <div className='contract-list-add-new-inside'>
                                                <img src={plus_icon_btn} />
                                                <p>New Contract</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                {authDone ?
                                    (
                                        allContracts.length !== 0 ? allContracts.map((e) => {
                                            return (
                                                <DashboardCard
                                                    key={e._id}
                                                    name={e.contractName}
                                                    productId={e.productId}
                                                    contractAddress={e.contractAddress}
                                                    networkId={e.networkId}
                                                />
                                            )
                                        })
                                            :
                                            (backendDown ?
                                                <div className='dashboard-pls-sign-message'>
                                                    <p>{`Our servers are currently offline. We are upgrading the backend. Please try again later.`}</p>
                                                    <div className=""><CircularProgress /></div>
                                                </div>
                                                :
                                                (
                                                    !contractsLoading ?
                                                        <div className='dashboard-pls-sign-message'>
                                                            <p>{`No contracts yet.`}</p>
                                                            <p>{`Start creating!`}</p>
                                                        </div>
                                                        :
                                                        <div className='dashboard-pls-sign-message'>
                                                            <p>{`Loading contracts...`}</p>
                                                            <div className=""><CircularProgress /></div>
                                                        </div>
                                                )
                                            )
                                    )
                                    :

                                    (backendDown ?
                                        <div className='dashboard-pls-sign-message'>
                                            <p>{`Our servers are currently offline. We are upgrading the backend. Please try again later.`}</p>
                                            <div className=""><CircularProgress /></div>
                                        </div>
                                        :
                                        <div className='dashboard-pls-sign-message'>
                                            <p>{`Sign message in your Metamask to prove you have access to this wallet and we’ll log you in. This won’t cost you any Ether.`}</p>
                                            <div className=""><CircularProgress /></div>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
