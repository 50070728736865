import React, { useEffect } from "react";
import './Header.css';
import droplove_new_logo_left from '../../assets/droplove_new_logo_left.png'
import discord from '../../assets/discord.png'
import twitter from '../../assets/twitter.png'

import { Container } from '@material-ui/core'
import DroplovePassNFT from '../../contracts/DroplovePassNFT.json'
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../components/Wallet/connector"

const Header = (props) => {

  const { active, account, library, connector, activate, deactivate } = useWeb3React()

  const connectWallet = async () => {
    try {
      await activate(injected)
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <div className="fixed-header">
      <Container className="no-padding" maxWidth="xl">
        <div className="container-fluid">
          <div className="droplove-header">
            <div className="droplove-logo">
              <a href="https://droplove.xyz/">
                <img className="logo-left" src={droplove_new_logo_left} alt="logo" />
                <h1>Droplove</h1>
              </a>
            </div>
            <div className="droplove-menu">
              <ul>
                <li>
                  <a target="_blank" className="apply-btn" href="https://droplove.gitbook.io/droplove-docs/">Docs</a>
                  <a target="_blank" className="apply-btn" href="https://medium.com/droplove">Blog</a>
                  <a href="https://discord.gg/juB69MkDF2"><img width="24" src={discord} className="" alt="logo" /></a>
                  <a href="https://twitter.com/droplovexyz"><img width="24" src={twitter} className="" alt="logo" /></a>
                  {/* <a className="apply-btn" href="#">Explore</a> */}
                  <a className="apply-btn" href="/create">Create</a>
                  {active ? <a className="apply-btn" href="/">Dashboard</a> : null}
                  <a className="mobile-hide" onClick={() => connectWallet()} href="#"><button className="full-btn">{active ? account.slice(0, 8) + '...' : 'Connect wallet'}</button></a>
                </li>
              </ul>
            </div>
          </div>
          <a className="desktop-hide" onClick={() => connectWallet()} href="#"><button className="full-btn">{active ? account.slice(0, 8) + '...' : 'Connect wallet'}</button></a>
        </div>
      </Container>
    </div>
  );
}

export default Header;
