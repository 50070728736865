import React, { useEffect, useState } from "react";
import './DashboardCard.css';

import { Container } from '@material-ui/core'

const DashboardCard = (props) => {

    const [etherscanLink, setEtherscanLink] = useState();

    useEffect(() => {
        if (props.networkId) {
            checkNetworkIdForEthscan()
        }
    }, []);

    const checkNetworkIdForEthscan = () => {
        switch (props.networkId) {
            case 1:
                setEtherscanLink(`https://etherscan.io/token/${props.contractAddress}`)
                break;
            case 3:
                setEtherscanLink(`https://ropsten.etherscan.io/token/${props.contractAddress}`)
                break;
            case 4:
                setEtherscanLink(`https://rinkeby.etherscan.io/token/${props.contractAddress}`)
                break;
            case 42:
                setEtherscanLink(`https://kovan.etherscan.io/token/${props.contractAddress}`)
                break;
            case 5:
                setEtherscanLink(`https://goerli.etherscan.io/token/${props.contractAddress}`)
                break;
        }
    }

    return (
        <div className="col-md-4 animate__animated animate__fadeIn">
            <div className="dashboard-card">
                <div className={parseInt(props.productId) === 1 ? "dashboard-card-top" : "dashboard-card-top-public-mint"}>

                </div>
                <h3>{props.name}</h3>
                <h4>{parseInt(props.productId) === 1 ? 'Airdrop mint' : 'Public Mint'}</h4>
                {console.log('props.networkId ', props.networkId)}
                {parseInt(props.networkId) === 1 || parseInt(props.networkId) === 5 ?
                    <a href={`/${props.productId === 1 ? 'contract' : 'contract-pm'}/${props.networkId}/${props.contractAddress}`}><button className="dashboard-edit-btn">Edit contract</button></a>
                    :
                    null
                }
                <br />
                <a target="_blank" href={`${etherscanLink}`}><button className="dashboard-ethscan-btn">See on Etherscan</button></a>
            </div>
        </div>
    );
}

export default DashboardCard;
