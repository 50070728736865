// import logo from './logo.svg';
import './App.css';
import 'animate.css'
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"
import Home from "./pages/Home/Home"
import Create from "./pages/Create/Create"
import Dashboard from './pages/Dashboard/Dashboard';
import SilentMint from './pages/SilentMint/SilentMint';
import PublicMint from './pages/PublicMint/PublicMint';
import EditProfile from './pages/EditProfile/EditProfile';
import ContractDetail from './pages/ContractDetail/ContractDetail';
import PMContractDetail from './pages/PMContractDetail/PMContractDetail';
import MintPage from './pages/MintPage/MintPage';

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <Router>
          <Header />
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/edit-profile" exact component={EditProfile} />
            <Route path="/contract/:networkId/:contractAddress" exact component={ContractDetail} />
            <Route path="/contract-pm/:networkId/:contractAddress" exact component={PMContractDetail} />
            <Route path="/create" exact component={Create} />
            <Route path="/create/airdrop-mint" exact component={SilentMint} />
            <Route path="/create/public-mint" exact component={PublicMint} />
            <Route path="/nft/:networkId/:contractAddress" exact component={MintPage} /> /** TODO: nazov namiesto adresy contractu */
            <Route>404 Not Found!</Route>
          </Switch>
          <Footer />
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
