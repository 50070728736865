import sourceCode from '../contract_abi/NFTContractFlattened'
import axios from 'axios'

// Droplove UAT: RC5GRTAX5SC9F42BUPN4ZTAQA89JIM1JWK
// Droplove Production: JH6JJAEV76WFDRC5584GC36D6KKE8UDI3G

export const validate = (_contractAddress, _constructorArguments, _networkId) => {

    let api;

    switch (parseInt(_networkId)) {
        case 1:
            api = '//api.etherscan.io/api'
            break;
        case 5:
            api = '//api-goerly.etherscan.io/api'
            break;
    }

    // console.log('_contractAddress, _constructorArguments, _networkId ', _contractAddress, _constructorArguments, _networkId)

    try {
        const res = axios.post(api, {
            "apikey": 'JH6JJAEV76WFDRC5584GC36D6KKE8UDI3G',  // REAL API KEY      
            "module": "contract",
            "action": 'verifysourcecode',
            "sourceCode": sourceCode,
            "contractaddress": _contractAddress,
            "codeformat": 'solidity-single-file',
            "contractname": 'NFT',
            "compilerversion": 'v0.8.7+commit.e28d00a7', //'v0.8.7+commit.e28d00a7', //'v0.8.0+commit.c7dfd78e',
            "optimizationUsed": 0,
            "runs": "",
            "constructorArguements": _constructorArguments,
            "evmversion": '', // instanbul
            "licenceType": 1
        })
        res.then(e => {
            console.log('Validated ', e)
        })
    } catch (err) {
        console.error('Validation error ', err)
    }
}