import './ContractDetail.css';
//assests import
import icon_button_back from '../../assets/Icon-button.png'
import minted_item_placeholder from '../../assets/no_img_placeholder.png'
import coinbase_nft_icon from '../../assets/coinbase-nft-icon.png'
import openseea_nft_icon from '../../assets/openseea-nft-icon.png'
import contract_detail_background from '../../assets/contract-detail-background.png'
import metadata_defail_file_logo_white from '../../assets/metadata/white_logo.png'
import metadata_defail_file_logo_black from '../../assets/metadata/black_logo.png'
import metadata_file_black_logo_dark_bg from '../../assets/metadata/black_logo_dark_bg.jpg'
import metadata_file_black_logo_light_bg from '../../assets/metadata/black_logo_light_bg.jpg'
import metadata_file_white_logo_dark_bg from '../../assets/metadata/white_logo_dark_bg.jpg'
import metadata_file_white_logo_light_bg from '../../assets/metadata/white_logo_light_bg.jpg'
import metadata_file_key_black_dark_bg from '../../assets/metadata/key_black_dark_bg.jpg'
import metadata_file_key_black_light_bg from '../../assets/metadata/key_black_light_bg.jpg'
import metadata_file_key_white_dark_bg from '../../assets/metadata/key_white_dark_bg.jpg'
import metadata_file_key_white_light_bg from '../../assets/metadata/key_white_light_bg.jpg'

import metadata_defail_file_sunset from '../../assets/metadata/sunset.png'
import plus_icon_btn from '../../assets/dashboard-Icon-button.png'
import close_icon_btn from '../../assets/btn-close.png'
import metamask_anim from '../../assets/metamask_anim.gif'
import ERC721FullABI from '../../contract_abi/ERC721Full.json'

//libs imports
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { Container, CircularProgress, LinearProgress, TextField, Modal } from '@material-ui/core'
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../components/Wallet/connector"
// import { GalleryItem } from "../../components/GalleryItem/GalleryItem"
import axios from 'axios'
import ImageUploading from "react-images-uploading";
import { api_uri } from "../../utils/networkSetup"
var PROD_OR_DEV_API = api_uri()

const ContractDetail = () => {

    const params = useParams();
    let history = useHistory();
    const { active, account, library, connector, activate, deactivate } = useWeb3React()
    const [inputData, setInputData] = useState();
    const [wrongNetwork, setWrongNetwork] = useState(false);
    const [name, setName] = useState();
    const [symbol, setSymbol] = useState();
    const [ipfsLink, setIpfsLink] = useState();
    const [contractOwner, setContractOwner] = useState();
    const [maxSupply, setMaxSupply] = useState();
    const [totalSupply, setTotalSupply] = useState();
    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(10);
    const [loading, setLoading] = useState(false);
    const [mintLoading, setMintLoading] = useState(false);
    const [updateUriLoading, setUpdateUriLoading] = useState(false)
    const [updateMetadataNameLoading, setUpdateMetadataNameLoading] = useState(false)
    const [updateMetadataDescriptionLoading, setUpdateMetadataDescriptionLoading] = useState(false)
    const [updateMetadataWebsiteLoading, setUpdateMetadataWebsiteLoading] = useState(false)
    const [updateMetadataImageLink, setUpdateMetadataImageLink] = useState(false)
    const [updateMetadataImageLoading, setUpdateMetadataImageLoading] = useState(false)
    const [updateOwnerLoading, setUpdateOwnerLoading] = useState(false)
    const [metadataSaveLoading, setMetadataSaveLoading] = useState(false)
    const [etherscanLink, setEtherscanLink] = useState();
    const [ntwId, setNtwId] = useState();
    const [networkName, setNetworkName] = useState();
    const [loadedContractData, setLoadedContractData] = useState();
    const [loadedMetadata, setLoadedMetadata] = useState();
    const [userData, setUserData] = useState();
    const [defaultMetadata, setDefaultMetadata] = useState(); // https://dirask.com/posts/Simple-way-to-use-multiple-radio-buttons-in-React-1xVY4p
    const progressRef = useRef(() => { });
    const uploadElement = useRef();
    const [imagePreview, setImagePreview] = useState(null)
    const [open, setModalOpen] = useState(false);
    const [galleryImageLink, setGalleryImageLink] = useState('');

    useEffect(() => {
        document.body.style.backgroundImage = `url(${contract_detail_background})`
        document.getElementsByClassName("fixed-header")[0].style.backgroundImage = `url(${contract_detail_background})`
        if (!active) {
            connectWallet()
        } else {
            if (JSON.parse(localStorage.getItem("user"))) {
                loadContractProps(params.contractAddress)
                loadUserData()
                checkNetwork()
            } else {
                window.location.href = 'http://app.droplove.xyz/'
            }
        }
    }, [active]);

    useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });

    useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const connectWallet = async () => {
        try {
            await activate(injected)
        } catch (ex) {
            console.log(ex)
        }
    }

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const onImagePreviewChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImagePreview(URL.createObjectURL(event.target.files[0]));
        }
    }

    const triggerInputFile = () => {
        !imagePreview && uploadElement.current.click()
    }

    const triggerChangeInputFile = () => {
        uploadElement.current.click()
    }

    const loadUserData = async () => {
        setLoading(true)
        try {
            const resUserData = await axios.get(`${PROD_OR_DEV_API}/user-data/${account}`, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            if (resUserData.data.length != 0) {
                setUserData(resUserData.data[0])
            }
            setLoading(false);
        } catch (err) {
            console.error('err ', err)
        }
    }

    const checkNetwork = async () => {
        const networkId = await library.eth.net.getId();
        setNtwId(networkId)
        if (params.networkId != networkId) {
            setWrongNetwork(true)
        } else {
            checkNetworkIdForEthscan(params.networkId)
            getNetworkName(params.networkId)
        }
    }

    const getGalleryData = () => {
        return [
            {
                _id: 1,
                itemValue: "metadata_file_black_logo_dark_bg",
                itemLabel: "Black Droplove Logo Dark background",
                itemPrice: "Free",
                itemUrl: "https://res.cloudinary.com/dgfwqjr7c/image/upload/v1674121739/Metadata%20gallery/black_logo_dark_bg_feo2vz.jpg"
            },
            {
                _id: 2,
                itemValue: "metadata_file_black_logo_light_bg",
                itemLabel: "Black Droplove Logo Kight background",
                itemPrice: "Free",
                itemUrl: "https://res.cloudinary.com/dgfwqjr7c/image/upload/v1674121739/Metadata%20gallery/black_logo_light_bg_zszn9w.jpg"
            },
            {
                _id: 3,
                itemValue: "metadata_file_white_logo_dark_bg",
                itemLabel: "White Droplove Logo Dark background",
                itemPrice: "Free",
                itemUrl: "https://res.cloudinary.com/dgfwqjr7c/image/upload/v1674121739/Metadata%20gallery/white_logo_dark_bg_s9knwd.jpg"
            },
            {
                _id: 4,
                itemValue: "metadata_file_white_logo_light_bg",
                itemLabel: "White Droplove Logo Light background",
                itemPrice: "Free",
                itemUrl: "https://res.cloudinary.com/dgfwqjr7c/image/upload/v1674121739/Metadata%20gallery/white_logo_light_bg_a5aovl.jpg"
            },
            {
                _id: 5,
                itemValue: "metadata_file_key_black_dark_bg",
                itemLabel: "Black Key Dark background",
                itemPrice: "Free",
                itemUrl: "https://res.cloudinary.com/dgfwqjr7c/image/upload/v1674121738/Metadata%20gallery/key_black_dark_bg_uakbmi.jpg"
            },
            {
                _id: 6,
                itemValue: "metadata_file_key_black_light_bg",
                itemLabel: "Black Key Light background",
                itemPrice: "Free",
                itemUrl: "https://res.cloudinary.com/dgfwqjr7c/image/upload/v1674121739/Metadata%20gallery/key_black_light_bg_i3xxsr.jpg"
            },
            {
                _id: 7,
                itemValue: "metadata_file_key_white_dark_bg",
                itemLabel: "White Key Dark background",
                itemPrice: "Free",
                itemUrl: "https://res.cloudinary.com/dgfwqjr7c/image/upload/v1674121739/Metadata%20gallery/key_white_dark_bg_nupxje.jpg"
            },
            {
                _id: 8,
                itemValue: "metadata_file_key_white_light_bg",
                itemLabel: "White Key Light background",
                itemPrice: "Free",
                itemUrl: "https://res.cloudinary.com/dgfwqjr7c/image/upload/v1674121739/Metadata%20gallery/key_white_light_bg_igncwy.jpg"
            },
            {
                _id: 9,
                itemValue: "metadata_defail_file_sunset",
                itemLabel: "Sunset background",
                itemPrice: "Free",
                itemUrl: "https://res.cloudinary.com/dgfwqjr7c/image/upload/v1669888021/Metadata%20gallery/sunset_ighuyi.png"
            }
        ]
    }

    const getContractMetadata = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${PROD_OR_DEV_API}/metadata/${params.contractAddress}/0`, {
                headers: {
                    // token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            setLoadedMetadata(res.data)
            setLoading(false);
        } catch (err) {
            console.error('err ', err)
        }
    }

    const getContractData = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${PROD_OR_DEV_API}/contracts/detail/${params.contractAddress}`, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            setLoadedContractData(res.data[0])
            setLoading(false);
        } catch (err) {
            console.error('err ', err)
        }
    }

    const loadContractProps = async (_contractAddress) => {
        getContractData()
        getContractMetadata()
        try {
            const networkId = await library.eth.net.getId();
            const contract = new library.eth.Contract(
                ERC721FullABI.abi,
                params.contractAddress
            );

            setName(await contract.methods
                .name()
                .call())

            setSymbol(await contract.methods
                .symbol()
                .call())

            setIpfsLink(await contract.methods
                ._baseTokenURI()
                .call())

            setContractOwner(await contract.methods
                .owner()
                .call())

            setTotalSupply(await contract.methods
                .totalSupply()
                .call())

            setMaxSupply(await contract.methods
                ._maxSupply()
                .call())

        } catch (error) {
            console.error('ERROR LOADING CONTRACT PROPS: ', error)
        }
    }

    const checkNetworkIdForEthscan = (_networkId) => {
        switch (parseInt(_networkId)) {
            case 1:
                setEtherscanLink(`https://etherscan.io/token/${params.contractAddress}`)
                break;
            case 3:
                setEtherscanLink(`https://ropsten.etherscan.io/token/${params.contractAddress}`)
                break;
            case 4:
                setEtherscanLink(`https://rinkeby.etherscan.io/token/${params.contractAddress}`)
                break;
            case 42:
                setEtherscanLink(`https://kovan.etherscan.io/token/${params.contractAddress}`)
                break;
            case 5:
                setEtherscanLink(`https://goerli.etherscan.io/token/${params.contractAddress}`)
                break;
        }
    }

    const setTokenIdLinks = (_networkId, _tokenId) => {
        switch (parseInt(_networkId)) {
            case 1:
                return `https://etherscan.io/token/${params.contractAddress}?a=${_tokenId}`
                break;
            case 3:
                return `https://ropsten.etherscan.io/token/${params.contractAddress}?a=${_tokenId}`
                break;
            case 4:
                return `https://rinkeby.etherscan.io/token/${params.contractAddress}?a=${_tokenId}`
                break;
            case 42:
                return `https://kovan.etherscan.io/token/${params.contractAddress}?a=${_tokenId}`
                break;
            case 5:
                return `https://goerli.etherscan.io/token/${params.contractAddress}?a=${_tokenId}`
                break;
        }
    }

    const handleMintNewTokens = async (e) => {
        e.preventDefault();

        let resolvedEnsAddress;

        try {
            resolvedEnsAddress = await library.eth.ens.getAddress(inputData.inputMintWallet.data);
        } catch (err) {
            if (err) {
                console.error('ENS ERROR ', err)
                resolvedEnsAddress = inputData.inputMintWallet.data;
            }
        }

        setMintLoading(true);

        const contract = new library.eth.Contract(
            ERC721FullABI.abi,
            params.contractAddress
        );

        let newId = parseInt(totalSupply) + 1
        // let newId = inputData.inputMintTokenId.data

        try {
            const receipt = await contract.methods
                .mint(resolvedEnsAddress, newId)
                .send({ from: account });

            handleMintSaveToDb(newId)

            getContractData()

            setTotalSupply(await contract.methods
                .totalSupply()
                .call())

            setMintLoading(false);
        } catch (error) {
            alert(error.message);
            console.error(error);
        }
    }

    const handleUpdateOwner = async (e) => {
        e.preventDefault();

        setUpdateOwnerLoading(true);

        let resolvedEnsAddress;

        try {
            resolvedEnsAddress = await library.eth.ens.getAddress(inputData.inputNewOwner.data);
        } catch (err) {
            if (err) {
                console.error('ENS ERROR ', err)
                resolvedEnsAddress = inputData.inputNewOwner.data;
            }
        }

        const contract = new library.eth.Contract(
            ERC721FullABI.abi,
            params.contractAddress
        );

        try {
            const receipt = await contract.methods
                .trasferDrplvOwnership(resolvedEnsAddress)
                .send({ from: account, value: 1000000000000000000 });

            setContractOwner(await contract.methods
                .owner()
                .call())

            handleUpdateOwnerInDb(e, resolvedEnsAddress)

            setUpdateOwnerLoading(false);
        } catch (error) {
            alert(error.message);
            console.error(error);
        }
    }

    const handleUpdateUri = async (e) => {
        e.preventDefault();

        setUpdateUriLoading(true);

        const contract = new library.eth.Contract(
            ERC721FullABI.abi,
            params.contractAddress
        );

        try {
            const receipt = await contract.methods
                .setBaseURI(inputData.inputUpdateUri.data)
                .send({ from: account });

            setIpfsLink(await contract.methods
                ._baseTokenURI()
                .call())

            setUpdateUriLoading(false);
        } catch (error) {
            alert(error.message);
            console.error(error);
        }
    }

    const saveMetadataUri = async (e) => {
        e.preventDefault();

        setMetadataSaveLoading(true);

        const contract = new library.eth.Contract(
            ERC721FullABI.abi,
            params.contractAddress
        );

        try {
            const receipt = await contract.methods
                .setBaseURI(`${PROD_OR_DEV_API}/metadata/${params.contractAddress}/`)
                .send({ from: account });

            setIpfsLink(await contract.methods
                ._baseTokenURI()
                .call())

            setMetadataSaveLoading(false);
        } catch (error) {
            alert(error.message);
            console.error(error);
        }
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setInputData({ ...inputData, [e.target.name]: { "data": value, "errorText": "" } })
    }

    const getNetworkName = (_networkId) => {
        switch (parseInt(_networkId)) {
            case 1:
                setNetworkName(`Ethereum Mainnet`)
                break;
            case 3:
                setNetworkName(`Ropsten Test Network (read only)`)
                break;
            case 42:
                setNetworkName(`Kovan Test Network (read only)`)
                break;
            case 4:
                setNetworkName(`Rinkeby Test Network (read only)`)
                break;
            case 5:
                setNetworkName(`Goerli Test Network`)
                break;
        }
    }

    const handleUpdateDescription = async (e) => {
        e.preventDefault();

        setLoading(true);

        try {
            const res = await axios.put(`${PROD_OR_DEV_API}/contracts/${loadedContractData._id}`, {
                "contractDescription": inputData.inputTokenDescription.data
            }, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            getContractData()
            setLoading(false);
        } catch (err) {
            console.error('err ', err)
        }
    }

    const handleUpdateOwnerInDb = async (e, _newOwner) => {
        e.preventDefault();

        setLoading(true);

        try {
            const res = await axios.put(`${PROD_OR_DEV_API}/contracts/${loadedContractData._id}`, {
                "userOwnerAddress": _newOwner
            }, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            getContractData()
            setLoading(false);
        } catch (err) {
            console.error('err ', err)
        }
    }

    const deleteContract = async (e) => {
        e.preventDefault();

        if (window.confirm('Are you sure you want to delete this contract from your account? It will be never deleted from Blockchain')) {
            const res = await axios.delete(`${PROD_OR_DEV_API}/contracts/${loadedContractData._id}`, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            history.push("/");
        } else {
            console.log('Ok');
        }
    }

    const handleMintSaveToDb = async (_mintedId) => {

        setLoading(true);

        try {
            const res = await axios.put(`${PROD_OR_DEV_API}/contracts/${loadedContractData._id}`, {
                "mindedIds": [...loadedContractData.mindedIds, _mintedId]
            }, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            getContractData()
            setLoading(false);
        } catch (err) {
            console.error('err ', err)
        }
    }

    const openseaMarketLinks = (_ntwrkId, _contractAddress, _tokenId) => {
        switch (parseInt(_ntwrkId)) {
            case 1:
                return `https://opensea.io/assets/ethereum/${_contractAddress}/${_tokenId}`
            case 4:
                return `https://testnets.opensea.io/assets/ethereum/${_contractAddress}/${_tokenId}`
            default:
                return `https://opensea.io/`
        }
    }

    const coinbaseMarketLinks = (_ntwrkId, _contractAddress, _tokenId) => {
        switch (parseInt(_ntwrkId)) {
            case 1:
                return `https://nft.coinbase.com/nft/ethereum/${_contractAddress}/${_tokenId}`
            case 4:
                return `https://nft.coinbase.com/`
            default:
                return `https://nft.coinbase.com/`
        }
    }

    const submitCustomMetadataName = async (e) => {
        e.preventDefault()
        setUpdateMetadataNameLoading(true)
        try {
            const res = await axios.put(`${PROD_OR_DEV_API}/metadata/${loadedMetadata._id}`, {
                name: inputData.inputUpdateMetadataName.data
            }, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            getContractMetadata()
            setUpdateMetadataNameLoading(false)
        } catch (err) {
            console.error('Axios error ', err)
        }
    }

    const submitCustomMetadataDescription = async (e) => {
        e.preventDefault()
        setUpdateMetadataDescriptionLoading(true)
        try {
            const res = await axios.put(`${PROD_OR_DEV_API}/metadata/${loadedMetadata._id}`, {
                description: inputData.inputUpdateMetadataDescription.data
            }, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            getContractMetadata()
            setUpdateMetadataDescriptionLoading(false)
        } catch (err) {
            console.error('Axios error ', err)
        }
    }

    const submitCustomMetadataWebsite = async (e) => {
        e.preventDefault()
        setUpdateMetadataWebsiteLoading(true)
        try {
            const res = await axios.put(`${PROD_OR_DEV_API}/metadata/${loadedMetadata._id}`, {
                website: inputData.inputUpdateMetadataWebsite.data
            }, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            getContractMetadata()
            setUpdateMetadataWebsiteLoading(false)
        } catch (err) {
            console.error('Axios error ', err)
        }
    }

    const submitCustomMetadataGallery = async (e) => {
        e.preventDefault()
        if (galleryImageLink === '') {
            return false;
        }
        setUpdateMetadataImageLink(true)
        try {
            const res = await axios.put(`${PROD_OR_DEV_API}/metadata/${loadedMetadata._id}`, {
                image: galleryImageLink
            }, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            getContractMetadata()
            setUpdateMetadataImageLink(false)
            setModalOpen(false)
        } catch (err) {
            console.error('Axios error ', err)
        }
    }

    const submitCustomUploadedMetadata = async (e) => {
        e.preventDefault()

        setUpdateMetadataImageLoading(true)
        const input = document.getElementById('fileinput');
        var formData = new FormData()
        formData.append('image', input.files[0])

        try {
            const res = await axios.put(`${PROD_OR_DEV_API}/metadata/upload/${loadedMetadata._id}`, formData, {}, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            getContractMetadata()
            setUpdateMetadataImageLoading(false)
            setImagePreview(null);
        } catch (err) {
            console.error('Axios error ', err)
        }
    }

    const onChangeGalleryImage = async (_imageLink, _defaultMetadata) => {
        setGalleryImageLink(_imageLink)
        setDefaultMetadata(_defaultMetadata)
    }

    return (
        <div>
            {wrongNetwork ?
                <div className='detail-top-header'>
                    <br />
                    <br />
                    <br />
                    <h2>You are trying to load data from incorrect network.</h2>
                    <br />
                    {params.networkId == 1 ? "Please change your metamask network to 'Mainnet' and refresh page." : null}
                    {params.networkId == 3 ? "Please change your metamask network to 'Ropsten' and refresh page." : null}
                    {params.networkId == 4 ? "Please change your metamask network to 'Rinkeby' and refresh page." : null}
                    {params.networkId == 42 ? "Please change your metamask network to 'Kovan' and refresh page." : null}
                    {params.networkId == 5 ? "Please change your metamask network to 'Goerli' and refresh page." : null}
                </div>
                :
                <div>
                    <div className='contract-detail-header-element'>
                        <div className="contract-detail-back-button">
                            <a href='/'><img src={icon_button_back} /></a>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className='droplove-page-dashboard-body'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='side-menu contract-detail-side-menu animate__animated animate__fadeIn'>
                                        <a href='#contract-properties'>
                                            <div className='side-menu-item'>
                                                <button className='side-menu-item-btn'>Contract Properties</button>
                                                <p>What is written on chain. Link your files here.</p>
                                            </div>
                                        </a>
                                        <a href='#transfer-contract-ownership'>
                                            <div className='side-menu-item'>
                                                <button className='side-menu-item-btn'>Contract ownership</button>
                                                <p>You can transfer ownership of this contract.</p>
                                            </div>
                                        </a>
                                        <a href='#mint-tokens'>
                                            <div className='side-menu-item'>
                                                <button className='side-menu-item-btn'>Mint or Airdrop Tokens</button>
                                                <p>You can mint or airdrop tokens to any wallet.</p>
                                            </div>
                                        </a>
                                        <a href='#minted-nfts'>
                                            <div className='side-menu-item'>
                                                <button className='side-menu-item-btn'>Minted NFTs</button>
                                                <p>Here you can find NFTs minted from this contract.</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className='col-md-9 contract-detail-sections-body'>
                                    <div className='detail-top-header'>
                                        <h2>Airdrop mint contract</h2>
                                        <h1 className='animate__animated animate__fadeIn'>{name ? name : <div className="loading_spinner_inline"><CircularProgress /></div>}</h1>
                                        <h3>{`by @${userData && userData.name}`}</h3>
                                        <span id='contract-properties'></span>
                                        <a target="_blank" href={`${etherscanLink}`}><button>Check on Etherscan</button></a>
                                    </div>
                                    <div className='detail-test-net-indicator animate__animated animate__fadeIn'>
                                        <button>{networkName}</button>
                                    </div>
                                    <hr />

                                    <div className='detail-section-public-properties animate__animated animate__fadeIn'>
                                        <h2>Public Contract Properties</h2>
                                        <h5>Data loaded directly from chain. These data are already written to blockchain, hence they are not possible to be updated. </h5>
                                        <div className='row contract-property-row'>
                                            <div className='col-md-6'>
                                                <p>Name</p>
                                                <span className='proplabel'>{name ? name : <div className="loading_spinner_inline"><CircularProgress /></div>}</span>
                                            </div>
                                            <div className='col-md-6'></div>
                                        </div>
                                        <div className='row contract-property-row'>
                                            <div className='col-md-6'>
                                                <p>Ticker</p>
                                                <span className='proplabel'>{symbol ? symbol : <div className="loading_spinner_inline"><CircularProgress /></div>}</span>
                                            </div>
                                            <div className='col-md-6'></div>
                                        </div>
                                        <div className='row contract-property-row'>
                                            <div className='col-md-6'>
                                                <p>Total Supply</p>
                                                <label>{totalSupply ? totalSupply : <div className="loading_spinner_inline"><CircularProgress /></div>}</label>
                                            </div>
                                            <div className='col-md-6'></div>
                                        </div>
                                        <div className='row contract-property-row'>
                                            <div className='col-md-6'>
                                                <p>Max Supply</p>
                                                <label>{maxSupply ? maxSupply : <div className="loading_spinner_inline"><CircularProgress /></div>}</label>
                                            </div>
                                            <div className='col-md-6'></div>
                                        </div>

                                    </div>
                                    <div className='contract-properties-metadata'>
                                        <div className='contract-properties-metadata-header'>
                                            <h1>NFTs set up</h1>
                                            <h5>Modify the look of your NFTs. You can change the look of NFTs made from this contract anytime. Even after they are minted.</h5>
                                        </div>
                                        <div className='contract-properties-metadata-body'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='cp-metadata-body-nft-overview'>
                                                        <div className='cp-metadata-body-nft-overview-top'>
                                                            <div onClick={triggerInputFile} className='uploader-component'>
                                                                <div className='uploader-clc-component'>
                                                                    {!imagePreview && loadedMetadata && loadedMetadata.image !== 'imageurl' &&
                                                                        <img src={loadedMetadata && loadedMetadata.image} />
                                                                    }
                                                                    {imagePreview &&
                                                                        <img src={imagePreview} />
                                                                    }
                                                                    <form id="upload_form" encType="multipart/form-data" name="image">
                                                                        {imagePreview || loadedMetadata && loadedMetadata.image !== 'imageurl' ?
                                                                            null
                                                                            :
                                                                            <div className='uploader-component-inside'>
                                                                                <img src={plus_icon_btn} />
                                                                                <p>Upload<br />custom image</p>
                                                                            </div>
                                                                        }
                                                                        <input
                                                                            type="file"
                                                                            accept=".jpg,.jpeg,.png,.gif"
                                                                            id="fileinput"
                                                                            ref={uploadElement}
                                                                            onChange={onImagePreviewChange}
                                                                        />
                                                                    </form>
                                                                </div>
                                                                <div className='uploader-component-bottom'>
                                                                    {!updateMetadataImageLoading ?
                                                                        (imagePreview ?
                                                                            <button className='upload-btn-change' onClick={triggerChangeInputFile}>Change image</button>
                                                                            :
                                                                            null)
                                                                        :
                                                                        null
                                                                    }
                                                                    <br />
                                                                    {!updateMetadataImageLoading ?
                                                                        (imagePreview ?
                                                                            <button className='upload-btn' onClick={(e) => submitCustomUploadedMetadata(e)} type="submit">Save image</button>
                                                                            :
                                                                            null)
                                                                        :
                                                                        <div className="upload-btn-loader"><CircularProgress /></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='cp-metadata-body-nft-overview-bottom'>
                                                            <div className='cp-metadata-data-title'>NFT Pass name</div>
                                                            <div className='cp-metadata-data-name'>{loadedMetadata && loadedMetadata.name}</div>
                                                            {loadedMetadata && !loadedMetadata.name ? <h6>NFT Pass unnamed</h6> : null}
                                                            <div className='cp-metadata-update-name-input'>
                                                                <TextField
                                                                    id="metadata-name-input"
                                                                    className={`contract-detail-ipfs-input`}
                                                                    variant="outlined"
                                                                    label="Metadata Name"
                                                                    name="inputUpdateMetadataName"
                                                                    onChange={handleChange}
                                                                />
                                                                {!updateMetadataNameLoading ?
                                                                    <button onClick={(e) => submitCustomMetadataName(e)} className='update-contract-button'>
                                                                        Update
                                                                    </button>
                                                                    :
                                                                    <div className="update-uri-button-loading"><CircularProgress /></div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='cp-metadata-body-gallery-button'>
                                                        <button onClick={handleOpen} className='cp-metadata-extra-big-button'>Choose an image for your NFTs</button>
                                                    </div>

                                                    <div className='cp-metadata-modal-gallery'>
                                                        <Modal
                                                            aria-labelledby="simple-modal-title"
                                                            aria-describedby="simple-modal-description"
                                                            open={open}
                                                            onClose={handleClose}
                                                        >
                                                            <div className='cp-metadata-modal-gallery-body'>
                                                                <div className='modal-gallery-header'>
                                                                    <h2>Choose image</h2>
                                                                    <img onClick={handleClose} src={close_icon_btn} />
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-md-3 pdr-0'>
                                                                        <div className='modal-gallery-sidemenu'>
                                                                            <ul>
                                                                                <li><a className='activestate'>Free samples</a></li>
                                                                                <li><a className='deactivestate'>Premium pics</a></li>
                                                                                <li><a className='deactivestate'>More coming soon</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-9'>
                                                                        <div className='modal-gallery-listing'>
                                                                            <div className='contract-properties-metadata-option'>
                                                                                <div className='row contract-properties-metadata-option-row'>
                                                                                    {/* <div onClick={() => onChangeGalleryImage("https://res.cloudinary.com/dgfwqjr7c/image/upload/v1669888021/Metadata%20gallery/white_logo_sfltru.png")} className='default-metadata-option-item'>
                                                                                        <RadioInput label="White Droplove hearth" value="metadata_defail_file_logo_white" checked={defaultMetadata} setter={setDefaultMetadata} />
                                                                                        <img src={metadata_defail_file_logo_white} />
                                                                                        <div className='default-metadata-price'>
                                                                                            Free
                                                                                        </div>
                                                                                    </div>
                                                                                    <div onClick={() => onChangeGalleryImage("https://res.cloudinary.com/dgfwqjr7c/image/upload/v1669888021/Metadata%20gallery/black_logo_marywf.png", "metadata_defail_file_logo_black")} className='default-metadata-option-item'>
                                                                                        <RadioInput label="Black Droplove hearth" value="metadata_defail_file_logo_black" checked={defaultMetadata} setter={setDefaultMetadata} />
                                                                                        <img src={metadata_defail_file_logo_black} />
                                                                                        <div className='default-metadata-price'>
                                                                                            Free
                                                                                        </div>
                                                                                    </div>

                                                                                    <div onClick={() => onChangeGalleryImage("https://res.cloudinary.com/dgfwqjr7c/image/upload/v1669888021/Metadata%20gallery/sunset_ighuyi.png", "metadata_defail_file_sunset")} className='default-metadata-option-item'>
                                                                                        <RadioInput label="Sunset beam" value="metadata_defail_file_sunset" checked={defaultMetadata} setter={setDefaultMetadata} />
                                                                                        <img src={metadata_defail_file_sunset} />
                                                                                        <div className='default-metadata-price'>
                                                                                            Free
                                                                                        </div>
                                                                                    </div> */}
                                                                                    {/* {console.log('getGalleryData ', getGalleryData().length)} */}
                                                                                    {
                                                                                        getGalleryData().length !== 0 ? getGalleryData().map((e) => {
                                                                                            return (
                                                                                                // <GalleryItem
                                                                                                //     key={e._id}
                                                                                                //     itemLabel={e.itemLabel}
                                                                                                //     itemName={e.itemValue}
                                                                                                //     itemPrice={e.itemPrice}
                                                                                                //     itemUrl={e.itemUrl}
                                                                                                // />
                                                                                                <div onClick={() => onChangeGalleryImage(e.itemUrl)} className='default-metadata-option-item'>
                                                                                                    <RadioInput label={e.itemLabel} value={e.itemValue} checked={defaultMetadata} setter={setDefaultMetadata} />
                                                                                                    <img src={e.itemUrl} />
                                                                                                    <div className='default-metadata-price'>
                                                                                                        {e.itemPrice}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                            :
                                                                                            "Loading error"
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='modal-gallery-footer'>

                                                                    {!updateMetadataImageLink ?
                                                                        <button onClick={(e) => submitCustomMetadataGallery(e)} className='upload-btn-change'>Save image</button>
                                                                        :
                                                                        <div className="update-modal-image-link-loading"><CircularProgress /></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Modal>
                                                    </div>

                                                    <div className='cp-metadata-body-nft-description'>
                                                        <div className='cp-metadata-data-description-title'>Description</div>
                                                        <div className='cp-metadata-data-description-data'>
                                                            {loadedMetadata && loadedMetadata.description ?
                                                                loadedMetadata.description
                                                                :
                                                                `No description, yet. Here is to show it.`
                                                            }
                                                        </div>
                                                        <div className='cp-metadata-update-description-input'>
                                                            <TextField
                                                                id="metadata-description-input"
                                                                className={`contract-detail-ipfs-input`}
                                                                variant="outlined"
                                                                label="Metadata Description"
                                                                name="inputUpdateMetadataDescription"
                                                                onChange={handleChange}
                                                            />
                                                            {!updateMetadataDescriptionLoading ?
                                                                <button onClick={(e) => submitCustomMetadataDescription(e)} className='update-contract-button'>
                                                                    Update
                                                                </button>
                                                                :
                                                                <div className="update-uri-button-loading"><CircularProgress /></div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='cp-metadata-body-nft-project-website'>
                                                        <div className='cp-metadata-data-project-website-title'>Project website</div>
                                                        <div className='cp-metadata-data-website-data'>
                                                            {loadedMetadata && loadedMetadata.website ?
                                                                loadedMetadata.website
                                                                :
                                                                `Add official website of the project.`
                                                            }
                                                        </div>
                                                        <div className='cp-metadata-update-website-input'>
                                                            <TextField
                                                                id="metadata-website-input"
                                                                className={`contract-detail-ipfs-input`}
                                                                variant="outlined"
                                                                label="Metadata website URL"
                                                                name="inputUpdateMetadataWebsite"
                                                                onChange={handleChange}
                                                            />
                                                            {!updateMetadataWebsiteLoading ?
                                                                <button onClick={(e) => submitCustomMetadataWebsite(e)} className='update-contract-button'>
                                                                    Update
                                                                </button>
                                                                :
                                                                <div className="update-uri-button-loading"><CircularProgress /></div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {!metadataSaveLoading ?
                                                    (ipfsLink === 'SETURI' ? <button className='save-extra-big-button' onClick={saveMetadataUri}>Save your metadata to blockchain</button> : null)
                                                    :
                                                    <button className='save-extra-big-button'><CircularProgress /></button>
                                                }
                                            </div>
                                            <div className='contract-properties-ipfs-files'>
                                                <h1>IPFS set up</h1>
                                                <div className='contract-properties-ipfs-files-text'>In order to create each NFT visualy unique, you need to provide an IPFS link to your files and metada file. You will be able to change the link anytime. </div>
                                                <div className='contract-properties-ipfs-files-text'>It is also possible to use any url link directing to files. They can be stored anywhere (Pinata, gdrive, dropbox, your own sever...etc.).</div>
                                                <div className='row contract-properties-ipfs-files-setup'>
                                                    <div className='col-md-6 proplabel-out'>
                                                        <span className='proplabel'>{ipfsLink === 'SETURI' ? 'No metadata found' : ipfsLink}</span>
                                                    </div>
                                                    <div className='col-md-6 contract-detail-update-uri-input-row'>
                                                        <TextField
                                                            id="contract-uri-input"
                                                            className={`contract-detail-ipfs-input`}
                                                            variant="outlined"
                                                            label="Standard URL"
                                                            name="inputUpdateUri"
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        {!updateUriLoading ?
                                                            <button onClick={handleUpdateUri} className='update-contract-button'>
                                                                Save link
                                                            </button>
                                                            :
                                                            <div className="update-uri-button-loading"><CircularProgress /></div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='contract-properties-ipfs-files-text'>
                                                    Note:
                                                    <ul>
                                                        <li>For skilled users only. Make sure your metadata are correct. <a href='https://medium.com/droplove/how-create-ipfs-link-for-your-nft-687e92da063c' target="_blank">Follow this link to learn more.</a> </li>
                                                        <li>If you already uploaded image, or picked one from prepared ones, you don’t need to worry about this section. Link always overrides the upload. </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id='transfer-contract-ownership' className='transfer-contract-ownership-section-header'>
                                        <h2>Contract ownership</h2>
                                    </div>
                                    <div className='detail-section-transfer-ownership animate__animated animate__fadeIn'>
                                        <h2>Transfer this contract </h2>
                                        <p>By transfering contract, you are giving away all the rights to the new owner and you will loose control over it. Please make sure it is what you want.</p>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='detail-section-transfer-ownership-label'>Contract owner</div>
                                                <div className='detail-section-transfer-ownership-label-data'><p>{contractOwner ? contractOwner : <div className="loading_spinner_inline"><CircularProgress /></div>}</p></div>
                                            </div>
                                            <div className='col-md-12 detail-section-update-owner-textfield'>
                                                <TextField
                                                    id="contract-name-input"
                                                    className={`contract-detail-owner-input`}
                                                    variant="outlined"
                                                    label="Wallet address"
                                                    name="inputNewOwner"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {!updateOwnerLoading ?
                                                    <>
                                                        <button onClick={handleUpdateOwner} className='update-contract-button'>Update owner</button>
                                                    </>
                                                    :
                                                    <div className="update-owner-button-loading"><CircularProgress /></div>
                                                }
                                            </div>
                                        </div>
                                        <br />
                                        <span className='transfer-ofnership-price'>Contract transfer costs 1 Eth</span>
                                        <span id='mint-tokens'></span>
                                    </div>
                                    <div className='detail-section-forget-contract animate__animated animate__fadeIn'>
                                        <button onClick={deleteContract} className=''>Delete contract from this account</button>
                                    </div>
                                    <div className='section-hr'></div>

                                    {totalSupply === maxSupply ?
                                        null
                                        :
                                        <div>
                                            <div className='mint-section-header'>
                                                <h2>Mint or Airdrop Tokens</h2>
                                            </div>
                                            <div className='detail-section-mint-tokens animate__animated animate__fadeIn'>
                                                <h2>Mint NFTs on this contract / Airdrop tokens</h2>
                                                <p>This is your deployed contract. Now you can mint some non fungible tokens to your wallet.<br />You can also airdrop token to anyone. You just need their wallet address.</p>
                                                <h6>Mint new tokens to your or anyone’s wallet one at a time.</h6>
                                                <form>
                                                    <div className='detail-section-inline-form-mint'>
                                                        {/* <TextField
                                                            id="mint-tokenId-input"
                                                            className={`contract-detail-input mt-10-input`}
                                                            variant="outlined"
                                                            label="Token ID"
                                                            name="inputMintTokenId"
                                                            onChange={handleChange}
                                                            required
                                                        /> */}
                                                        <TextField
                                                            id="mint-wallet-input"
                                                            className={`contract-detail-input-2 mt-10-input`}
                                                            variant="outlined"
                                                            label="Wallet address"
                                                            name="inputMintWallet"
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        {!mintLoading ?
                                                            <button onClick={handleMintNewTokens} className='mint-tokens-button'>
                                                                Mint tokens
                                                            </button>
                                                            :
                                                            <div className="mint-tokens-button-loading"><CircularProgress /></div>
                                                        }
                                                        <span id='minted-nfts'></span>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className='section-hr-mint'></div>
                                        </div>
                                    }
                                    <div className='detail-section-minted-tokens'>
                                        <h2>NFTs minted from this contract</h2>
                                        <div className='minted-items-row'>
                                            {
                                                loadedContractData && loadedContractData.mindedIds.length > 0 ? loadedContractData.mindedIds.map((e) => {
                                                    return (
                                                        <div key={e} className='minted-item animate__animated animate__fadeIn'>
                                                            <div className='minted-item-preview'>
                                                                <img src={loadedMetadata && loadedMetadata.image !== 'imageurl' ? loadedMetadata.image : minted_item_placeholder} />
                                                            </div>
                                                            <div className='minted-item-properties'>
                                                                <h4>{`#${e}`}</h4>
                                                                <p>{name ? name : null}</p>
                                                                <div className='minted-item-props-button-div'>
                                                                    <a target="_blank" href={setTokenIdLinks(ntwId, e)}>
                                                                        <button className='minted-item-props-btn'>See it on Etherscan</button>
                                                                    </a>
                                                                </div>
                                                                <div className='minted-item-props-links'>
                                                                    <a target="_blank" href={coinbaseMarketLinks(ntwId, loadedContractData.contractAddress, e)}><img src={coinbase_nft_icon} /></a>
                                                                    <a target="_blank" href={openseaMarketLinks(ntwId, loadedContractData.contractAddress, e)}><img src={openseea_nft_icon} /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                    :
                                                    <p>Nothing to show yet. Start minting!</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            }
        </div >
    );
}

const RadioInput = ({ label, value, checked, setter }) => {
    return (
        <label>
            <input type="radio" checked={checked == value}
                onChange={() => setter(value)} />
            <span>{label}</span>
        </label>
    );
};

export default ContractDetail;
