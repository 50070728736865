export const production = () => {
   return 'https://api.droplove.xyz/api'
}

export const uat = () => {
   return 'https://droplove-api-uat.herokuapp.com/api'
}

export const localhost = () => {
   return ''
}

export const api_uri = () => {
   return production() // SET BEFORE EVERY DEPLOY ON CLOUD
}