import React, { useEffect, useState } from "react";
import './Create.css';
import create_welcome_img from '../../assets/create_welcome_img.png'
import icon_button_back from '../../assets/Icon-button.png'
import airdrop_icon1 from '../../assets/airdrop-icon1.png'
import airdrop_icon2 from '../../assets/airdrop-icon2.png'
import airdrop_icon3 from '../../assets/airdrop-icon3.png'
import airdrop_icon4 from '../../assets/airdrop-icon4.png'
import public_mint_icon1 from '../../assets/public-mint-icon1.png'
import public_mint_icon2 from '../../assets/public-mint-icon2.png'
import public_mint_icon3 from '../../assets/public-mint-icon3.png'

import { Container } from '@material-ui/core'
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../components/Wallet/connector"
import { api_uri } from "../../utils/networkSetup"
var PROD_OR_DEV_API = api_uri()

const Create = (props) => {

    const [currentStep, setCurrentStep] = useState(1);
    const { active, account, library, connector, activate, deactivate } = useWeb3React()

    useEffect(() => {
        if (!active) {
            connectWallet()
        } else {
            // verify()
        }
    }, [active]);

    const connectWallet = async () => {
        try {
            await activate(injected)
        } catch (ex) {
            console.log(ex)
        }
    }

    const next = async () => {
        setCurrentStep(currentStep + 1);
    };

    const back = async () => {
        setCurrentStep(currentStep - 1);
    };

    switch (currentStep) {
        case 1:
            return (
                <div>
                    <Container className="no-padding" maxWidth="xl">
                        <div className="container droplove-page-create-body animate__animated animate__fadeIn">
                            <div className="create-welcome-section">
                                <h1>Let your assets grow - one NFT at a time</h1>
                                <p>Every NFT project is unique. Pick from options for personalised<br />experience and your NFT will will see the light of a day in no time. </p>
                                <img src={create_welcome_img} alt="Welcome to the NFT Minting Tool" />
                                <div>
                                    <button onClick={next} className="full-btn-big animate__animated animate__bounce">{`Start here >`}</button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            );
        case 2:
            return (
                <div>
                    <Container className="no-padding" maxWidth="xl">
                        <div className="container droplove-page-create-body">
                            <div className="wizard-back-button">
                                <img onClick={back} src={icon_button_back} />
                            </div>
                            <div className="create-step-silent-mint-section">
                                <div className="row">
                                    <div className="col-md-6 create-step-section-column">
                                        <h1>Airdrop mint</h1>
                                        <p>Non public mint, where you mint NFTs from your own contract. You can either mint them yourself, or airdrop them to a friend, or whitelist members.</p>
                                        <ul>
                                            <li><img src={airdrop_icon1} /> mint your NFT art and send it as a gift</li>
                                            <li><img src={airdrop_icon2} /> mint your own profile picture for twitter</li>
                                            <li><img src={airdrop_icon3} /> mint whitelist tokens </li>
                                            <li><img src={airdrop_icon4} /> mint keys to secret groups</li>
                                        </ul>
                                        <a href="/create/airdrop-mint"><button className="btn-white-wizard">{`Continue here`}</button></a>
                                    </div>
                                    <div className="col-md-6 create-step-section-column">
                                        <h1>Public mint</h1>
                                        <p>Public mint with option to setup public or whitelisted token sale and option to embed mint button to your own web site. Join discord to get notified about launch.</p>
                                        <ul>
                                            <li><img src={public_mint_icon1} /> deploy collectible collection to be minted</li>
                                            <li><img src={public_mint_icon2} /> set your own pre-sale and public dates</li>
                                            <li><img src={public_mint_icon3} /> set your own mint prices</li>
                                            <li><img src={airdrop_icon3} /> manage whitelist for your collection mint</li>
                                        </ul>
                                        <a href="/create/public-mint"><button className="btn-white-wizard">{`Continue here`}</button></a>
                                        {/* <button onClick={next} className="btn-white-wizard">{`Continue here`}</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            );
        case 3:
            return (
                <div>
                    <Container className="no-padding" maxWidth="xl">
                        <div className="container droplove-page-create-body">
                            <div className="wizard-back-button">
                                <img onClick={back} src={icon_button_back} />
                            </div>
                            <div className="create-welcome-section">
                                <h1>Uuuu, you are working on a collectible?<br />Let’s talk to make it right.</h1>
                                <p>We are more than happy to deploy such contract for you. Apply with<br />your project and we will get back to you within a week. </p>
                                <div>
                                    <a target="_blank" href="https://airtable.com/shrqf0aTNTDQYYoI2"><button className="btn-white-wizard">{`Apply`}</button></a>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            );
        default:
            return (
                <div>
                    <Container className="no-padding" maxWidth="xl">
                        <div className="container droplove-page-create-body">
                            <div className="create-welcome-section">
                                <h1>Let your assets grow - one NFT at a time</h1>
                                <p>Every NFT project is unique. Pick from options for personalised experience and your NFT will will see the light of a day in no time. </p>
                                <img src={create_welcome_img} alt="Welcome to the NFT Minting Tool" />
                                <div>
                                    <button onClick={next} className="full-btn-big">{`Start here >`}</button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            )
    }
}

export default Create;