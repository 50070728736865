import './EditProfile.css';
//assests import
import icon_button_back from '../../assets/Icon-button.png'
import add_profile_pic from '../../assets/profile_placeholder.png'
import globe_icon from '../../assets/globe-icon.png'
import twitter_icon from '../../assets/twitter.png'
import discord_icon from '../../assets/edit-profile-discord.png'
import edit_profile_background from '../../assets/edit-profile-background.png'

//libs imports
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, LinearProgress, TextField } from '@material-ui/core'
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../components/Wallet/connector"
import axios from "axios"
import { api_uri } from "../../utils/networkSetup"
var PROD_OR_DEV_API = api_uri()

const EditProfile = () => {

    let history = useHistory();
    const { active, account, library, connector, activate, deactivate } = useWeb3React()
    const [inputData, setInputData] = useState();
    const [loading, setLoading] = useState();
    const [loadedUserData, setLoadedUserData] = useState();
    const [profileLoading, setProfileLoading] = useState();

    useEffect(() => {
        document.body.style.backgroundImage = `url(${edit_profile_background})`
        if (!active) {
            connectWallet()
        } else {
            if (JSON.parse(localStorage.getItem("user"))) {
                checkUserExistanceOrLoadData()
            } else {
                // history.push("/");
                window.location.href = 'http://app.droplove.xyz/'
            }
        }
    }, [active]);

    const checkUserExistanceOrLoadData = async () => {
        setLoading(true)
        try {
            const res = await axios.get(`${PROD_OR_DEV_API}/user-data/${account}`, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            // console.log('checkUserExistanceOrLoadData ', res)
            if (res.data.length === 0) {
                // console.log('no data in profile ', JSON.parse(localStorage.getItem("user")))
                try {
                    const resNewAccount = await axios.post(`${PROD_OR_DEV_API}/user-data/new`, {
                        publicAddress: account,
                        name: "Unnamed",
                        profilePic: "",
                        website: "https://set your website",
                        twitter: "@yourTwitter",
                        discord: "Discord ID",
                        email: "",
                        bio: ""
                    }, {
                        headers: {
                            token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                        }
                    });
                    // setLoadedUserData(resNewAccount.data[0])
                    window.location.reload();
                } catch (err) {
                    console.error('err ', err)
                }
            } else {
                setLoadedUserData(res.data[0])
            }
            setLoading(false);
        } catch (err) {
            console.error('err ', err)
        }
    }

    const connectWallet = async () => {
        try {
            await activate(injected)
        } catch (ex) {
            console.log(ex)
        }
    }

    const handleChange = (e) => {
        const value = e.target.value;

        setInputData({ ...inputData, [e.target.name]: { "data": value, "errorText": "" } })
    }

    const handleSaveProfile = async (e) => {
        e.preventDefault();

        setLoading(true);

        // console.log('inputData.inputName.data ', inputData.inputName.data)

        try {
            const resUpdatedData = await axios.put(`${PROD_OR_DEV_API}/user-data/${loadedUserData._id}`, {
                publicAddress: loadedUserData.publicAddress,
                name: inputData && inputData.inputName && inputData.inputName.data ? inputData.inputName.data : loadedUserData.name,
                profilePic: "",
                website: inputData && inputData.inputWebsite && inputData.inputWebsite.data ? inputData.inputWebsite.data : loadedUserData.website,
                twitter: inputData && inputData.inputTwitter && inputData.inputTwitter.data ? inputData.inputTwitter.data : loadedUserData.twitter,
                discord: inputData && inputData.inputDiscord && inputData.inputDiscord.data ? inputData.inputDiscord.data : loadedUserData.discord,
                bio: ""
            }, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            // console.log('res ', resUpdatedData)
            checkUserExistanceOrLoadData()
            setLoading(false);
        } catch (err) {
            console.error('err ', err)
        }
    }

    const handleDeleteAccount = async (e) => {
        e.preventDefault();

        try {
            const deletedAccount = await axios.delete(`${PROD_OR_DEV_API}/user-data/${loadedUserData._id}`, {
                headers: {
                    token: "Bearer " + JSON.parse(localStorage.getItem("user"))
                }
            });
            // console.log('deletedAccount ', deletedAccount)
            history.push("/");
        } catch (err) {
            console.error('err ', err)
        }
    }

    const handleLogout = (e) => {
        e.preventDefault();

        if (JSON.parse(localStorage.getItem("user"))) {
            localStorage.setItem("user", null);
            history.push("/");
        } else {
            history.push("/");
        }
    }

    return (
        <div>
            <div className="container-fluid">
                <div className='droplove-page-dashboard-body'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className="profile-back-button">
                                <a href='/'><img src={icon_button_back} /></a>
                            </div>
                            <div className='side-menu side-menu-edit-profile'>
                                <a href='#profile'>
                                    <div className='side-menu-item'>
                                        <button className='side-menu-item-btn'>Profile</button>
                                        <p>Edit your public info, set your nickname, profile pic or social media links.</p>
                                    </div>
                                </a>
                                <a href='#settings'>
                                    <div className='side-menu-item'>
                                        <button className='side-menu-item-btn'>Settings</button>
                                        <p>You have the right to delete your personal data and be forgotten.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-md-9'>
                            <div id="profile" className='edit-top-header'>
                                <img src={add_profile_pic} />
                                <h1>{loadedUserData ? loadedUserData.name : '@Unnamed'}</h1>
                            </div>
                            <form>
                                <div className='edit-links'>
                                    <h3>Links</h3>
                                    <ul>

                                    </ul>
                                    <ul>
                                        <li>
                                            <img src={globe_icon} />
                                            <TextField
                                                id="name-input"
                                                className={`edit-profile-input`}
                                                variant="outlined"
                                                label="New nickname"
                                                name="inputName"
                                                helperText={loadedUserData ? loadedUserData.name : null}
                                                onChange={handleChange}
                                            />
                                        </li>
                                        <li>
                                            <img src={globe_icon} />
                                            <TextField
                                                id="website-input"
                                                className={`edit-profile-input`}
                                                variant="outlined"
                                                label="Website"
                                                name="inputWebsite"
                                                helperText={loadedUserData ? loadedUserData.website : null}
                                                onChange={handleChange}
                                            />
                                        </li>
                                        <li>
                                            <img src={twitter_icon} />
                                            <TextField
                                                id="twitter-input"
                                                className={`edit-profile-input`}
                                                variant="outlined"
                                                label="Twitter"
                                                name="inputTwitter"
                                                helperText={loadedUserData ? loadedUserData.twitter : null}
                                                onChange={handleChange}
                                            />
                                        </li>
                                        <li>
                                            <img src={discord_icon} />
                                            <TextField
                                                id="discord-input"
                                                className={`edit-profile-input`}
                                                variant="outlined"
                                                label="Discord"
                                                name="inputDiscord"
                                                helperText={loadedUserData ? loadedUserData.discord : null}
                                                onChange={handleChange}
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className='save-button'>
                                    <button onClick={handleSaveProfile} className='save-edit-btn'>Save All Changes</button>
                                </div>
                            </form>
                            <div id="settings" className='delete-profile-button'>
                                <button onClick={handleDeleteAccount} className='delete-profile-btn'>DELETE YOUR PERSONAL DATA</button>
                                <button onClick={handleLogout} className='delete-profile-btn'>LOG OUT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditProfile;
